angular.module('settingsService', [])
	.factory('Settings', ['$rootScope', '$http', function($rootScope, $http) {
		
		var settings;
		
		function loadSettings() {
			
			$http({
				method: 'GET',
				url: '/settings',
				params: {
					projectName: config.projectName,
					dbName: config.dbName
				}
			})
			.then(function (response){
				settings = response.data;
				
				// by default, if they don't exist we add red, green and amber colours
				if(!getSetting('greenColor')) {
					settings.push({
					    key : "greenColor",
					    value : "green"
					});
				}
				if(!getSetting('redColor')) {
					settings.push({
					    key : "redColor",
					    value : "red"
					});
				}
				if(!getSetting('amberColor')) {
					settings.push({
					    key : "amberColor",
					    value : "#FFC107"
					});
				}
				
				// we convert the Vmax start time into a Date
				for (var i = 0; i < settings.length; i++) {
					if(settings[i].key === 'velMaxStartTime') {
						settings[i].value = new Date(settings[i].value);
					}
				}
				
				$rootScope.$broadcast('settingsLoaded');
			},
			function (error){
				console.error(error);
			});
		};
		
		if(config.useLogin) {
			$rootScope.$on('loggedIn', function(event, loggedIn) {
				if(loggedIn) {
					loadSettings();
				}
				else {
					settings = null;
				}
			});
			if($rootScope.loggedIn) {
				loadSettings();
			}
		}
		else {
			loadSettings();
		}
		
		function onSettingsLoaded(callback) {
			// we check first if the settings have been loaded if not, then we wait
	    	if(settings) {
	    		callback();
	    	}
	    	else {
	    		setTimeout(function() {
	    			onSettingsLoaded(callback);
				}, 100);
	    	}
		};
		
		function hasSetting(key) {
			return getSetting(key) ? true : false;
		};
		
		function getSetting(key) {
			for (var i = 0; i < settings.length; i++) {
				if(settings[i].key === key) {
					return settings[i].value;
				}
			}
			return null;
		}
		
		return {
			onSettingsLoaded: onSettingsLoaded,
			hasSetting: hasSetting,
			getSetting: getSetting
		};
}]);
