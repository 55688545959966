var app = angular.module('msgWeb.controllers.Catalogue', [
  'ngRoute',
  'mobile-angular-ui',
  'mobile-angular-ui.core'
]);

var triggerTypeModes = ['all', 'microseis', 'earthquake', 'shot', 'noise', 'undef'];
var triggerTypeModesNoNoise = ['all', 'microseis', 'earthquake', 'shot', 'undef'];

app.controller('catalogueController', ['$scope', 'DataModel', 'Settings', '$timeout', '$rootScope', '$window',
                                       function($scope, DataModel, Settings, $timeout, $rootScope, $window) {
	$scope.dataSet = [];
	$scope.magnitudeSymbol = config.magnitudeSymbol;
	$scope.filteredDataSet = $scope.dataSet;
	$scope.loading = true;
	
	function updateFilteredDataSet() {
		if($scope.triggerTypeMode === 'all') {
			if($scope.hideNoiseTriggers) {
				$scope.filteredDataSet = [];
				$scope.dataSet.forEach(function(row){
					if(row.triggerType !== 'noise') {
						$scope.filteredDataSet.push(row);
					}
				});
			}
			else {
				$scope.filteredDataSet = $scope.dataSet;
			}
		}
		else {
			$scope.filteredDataSet = [];
			$scope.dataSet.forEach(function(row){
				if(row.triggerType === $scope.triggerTypeMode) {
					$scope.filteredDataSet.push(row);
				}
			});
		}
	}
	
	// trigger type
	$scope.triggerTypeModes = triggerTypeModesNoNoise;
	$scope.triggerTypeMode = 'all';
	$scope.updateTriggerTypeMode = function() {
		$timeout(function(){
			$scope.canHideNoiseTriggers = $scope.triggerTypeMode !== 'noise';
			updateFilteredDataSet();
		}, 10);
	};
	
	// noise filter
	$scope.canHideNoiseTriggers = true;
	$scope.hideNoiseTriggers = true;
	$scope.onHideNoiseTriggersChanged = function() {
		$timeout(function(){
			$scope.canHideNoiseTriggers = $scope.triggerTypeMode !== 'noise';
			$scope.triggerTypeModes = $scope.hideNoiseTriggers ? triggerTypeModesNoNoise : triggerTypeModes;
			updateFilteredDataSet();
		}, 10);
	};
	
	$scope.openSnapshot = function(rowID) {
		$timeout(function() {
			for (var i = 0; i < $scope.filteredDataSet.length; i++) {
				if($scope.filteredDataSet[i].ID == rowID) {
					var data = $scope.filteredDataSet[i];
					$window.open('/snapshot/' + data.project + '/' + data.imageName);
					break;
				}
			}
		}, 10);
	};
	
	// true when the user has changed the page
	var routeChanged = false;
	
	// we don't loads all the events straight away, we load them bit by bit to improve the response time
	function updateData() {
		DataModel.getCatalogueData(function(dataSet) {
			var nObjects = 25;
			var startIndex = 0;
			
			dataSet.sort(function(a, b) {
				return b.date - a.date;
			});
			
			dataSet.forEach(function(n, i){
				n.ID = i;
				if(n.x) {
					n.location = '(' + n.x.toFixed(1) + ', ' + n.y.toFixed(1) + ', ' + n.z.toFixed(1) + ')';
				}
			});
			
			DataModel.calcMaxVelocity(dataSet, Settings);
			
			var loadMore = function() {
				var min = startIndex, max = Math.min(startIndex + nObjects, dataSet.length);
				$timeout(function() {
					// we check that we are still on the same page
					if(!routeChanged) {
						for (var i = min; i < max; i++) {
							$scope.dataSet.push(dataSet[i]);
						}
						updateFilteredDataSet();
						$scope.loading = false;
					}
				});
				startIndex += nObjects;
				nObjects += 50; // we increase the number every time to go faster
				if(dataSet.length > startIndex && !routeChanged) {
					setTimeout(loadMore, 1);
				}
			};
			loadMore();
		}, true);
	}
	Settings.onSettingsLoaded(function() {
		$timeout(updateData);
	});
	
	function listener(event, args) {
		Settings.onSettingsLoaded(function() {
			$timeout(updateData);
		});
	};
	$scope.$on('triggerDataChanged', listener);
	$scope.$on('historicalTriggerDataChanged', listener);
	$scope.$on('eventDataChanged', listener);
	$scope.$on('historicalEventDataChanged', listener);
	$scope.$on('$routeChangeStart', function(next, current) {
		routeChanged = true;
	});
	
//	$rootScope.$on('analystChanged', function() {
//		updateData();
//	});
}]);
