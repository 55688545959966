var app = angular.module('msgWeb.controllers.peakGroundVelocityMap', [
                                                    'ngRoute',
                                                    'mobile-angular-ui',
                                                    'mobile-angular-ui.core',
                                                    'mobile-angular-ui.gestures.touch'
                                                    ]);

app.controller("peakGroundVelocityController",
		['$scope', 'MapBuilder', 'DataModel', 'SharedState', '$timeout', 'Settings',
			function($scope, MapBuilder, DataModel, SharedState, $timeout, Settings) {
	
	$scope.mapBuilder = MapBuilder;
	
	Settings.onSettingsLoaded(function() {
		
		var pgvConstantValues = Settings.getSetting("pgvConstantValues");
		if(pgvConstantValues) {
			$scope.sigmaMedian = pgvConstantValues.sigmaMedian;
			$scope.sigmaUpperBound = pgvConstantValues.sigmaUpperBound;
			$scope.sigma = $scope.sigmaMedian;
			$scope.hasPGVConstantValues = true;
		}
		
		// get the data
		DataModel.getProject(function(project) {
			$scope.projectLocation = {
				latitude: project.refLocation[1],
				longitude: project.refLocation[0]
			}
		});
		
		DataModel.getStations(function(stations) {
			$scope.stationLocations = MapBuilder.convertToMapPoints(stations);
		});
		
		DataModel.getTriggers(function(triggers) {
			$scope.triggers = triggers;
		});

		DataModel.getAllEvents(function(events) {
			$scope.eventLocations = MapBuilder.convertToMapPoints(events);
			events.forEach(function(event, i) {
				$scope.eventLocations[i].triggerID = event.triggerID;
				$scope.eventLocations[i].triggerType = event.triggerType;
				$scope.eventLocations[i].date = event.date;
				
				if(event.pgvCirclesData && event.pgvCirclesData.length > 0) {
					$scope.eventLocations[i].pgvCirclesData = event.pgvCirclesData;
				}
				if(event.rHypVelocities && event.rHypVelocities.length > 0) {
					$scope.eventLocations[i].rHypVelocities = event.rHypVelocities;
				}
			});
			$scope.eventLocations = $scope.eventLocations.filter(function(eventLocation) {
				return eventLocation.pgvCirclesData || eventLocation.rHypVelocities;
			});
			$scope.eventLocationsLoaded = true;
			
			var doInitMap = function() {
				loadStationVelocities($scope);
				initializePGVMap($scope, $timeout, MapBuilder);
			};
			$scope.initMap = function() {
				if($scope.stationLocations && $scope.projectLocation && $scope.triggers) {
					doInitMap();
				}
				else {
					$timeout(function() {
						$scope.initMap();
					});
				}
			};
			$scope.initMap();
		});
		
		// event sent from the side bar to select (set it as centre + show popup) an event or a station
		$scope.$on('selectObject', function(event, args) {
			$scope.eventLocation = null;
			
			if(args.type === 'event') {
				SharedState.turnOff('uiSidebarLeft'); // closes the side bar
				onCurrentEventHashChanged($scope, args.name)
			}
		});
	});
}]);

//Private Inner Functions
//--------------------------------------------------------------

/**
 * Gets the velocity for each station
 */
function loadStationVelocities($scope) {
	$scope.triggers.forEach(function(trigger) {
		
		$scope.eventLocations.forEach(function(eventLocation) {
			if(eventLocation.triggerID === trigger.triggerID) {
				eventLocation.stationVelocities = getStationVelocities(trigger.peakVelocity);
			}
		});
	});
};

function getStationVelocities(peakVelocities) {
	var stationVelocities = [];
	peakVelocities.forEach(function(peakVelocity) {
		if( peakVelocity.stationName !== "EGL0A"
			&& peakVelocity.stationName !== "EGL0B"
			&& peakVelocity.stationName !== "EGL0C") {
				
			var stationVelocity = getStationVelocity(stationVelocities, peakVelocity.stationName);
			if(stationVelocity) {
				if(stationVelocity.velocity < peakVelocity.peakVelocity) {
					stationVelocity.velocity = peakVelocity.peakVelocity;
				}
			}
			else {
				stationVelocities.push({
					stationName: peakVelocity.stationName,
					velocity: peakVelocity.peakVelocity,
				});
			}
		}
	});
	return stationVelocities;
};

function getStationVelocity(stationVelocities, stationName) {
	for (var i = 0; i < stationVelocities.length; i++) {
		if(stationVelocities[i].stationName === stationName) {
			return stationVelocities[i];
		}
	}
	return null;
};

/**
 * Initialises the map
 */
function initializePGVMap($scope, $timeout, MapBuilder) {	
	$scope.map = MapBuilder.createEmptyMap();
	var map = $scope.map;
	
	MapBuilder.addStationsAndOrigin(map, $scope.projectLocation, $scope.stationLocations);
	
	// we set the centre and zoom
	MapBuilder.fitLocations(map, $scope.stationLocations.concat($scope.eventLocations));
	map.zoomOut(3, { animate: false }); // decrease the zoom to actually see the PGV circles
	
	// Selected event info (empty for the moment as there is no selection)
	MapBuilder.createControl(map, 'bottomright',
			'<table id="eventInfoTable" style="font-size:12px"></table>',
			'info').addTo(map);
	
	// PGV legend
	var strHTML = 'Velocity <small>(mm/s)</small><br>'
					+ '<table id=pgvLegendTable>' + buildHtmlPGVLegendBody($scope, [])
					+ '</table>';
	MapBuilder.createControl(map, 'bottomleft', strHTML, 'info legend').addTo(map);
	
	// Standard deviation type control
	if($scope.hasPGVConstantValues) {
		$scope.standardDeviationTypeControl = MapBuilder.createControl(map, 'topright', 
				'Standard deviation<br/><select id="standardDeviationTypeComboBox">'
				+'<option selected="selected">Median</option>'
				+'<option>Upper bound</option>'
				+'</select>', 'info').addTo(map);
		
		var standardDeviationTypeComboBox = document.getElementById("standardDeviationTypeComboBox")
		standardDeviationTypeComboBox.addEventListener("click", function() {
			switch(standardDeviationTypeComboBox.value) {
			case 'Median':
				$scope.sigma = $scope.sigmaMedian;
				break;
				
			case 'Upper bound':
				$scope.sigma = $scope.sigmaUpperBound;
				break;
			}
			updatePGVLegendControl($scope, $scope.eventLocation.pgvCirclesData);
			drawCircles($scope, $scope.eventLocation.pgvCirclesData);
		}, false);
	}
	
	$scope.$on('$destroy', function() {
		map.remove();
	});
};

function buildHtmlPGVLegendBody($scope, circlesData) {
	var items = [];
	if($scope.hasPGVConstantValues) {
		circlesData.forEach(function(value) {
			if($scope.sigma == value.sigma) {
				items.push({
					dValue: value.value,
					value: '' + value.value,
					representation:
							'<svg style="width: 15px; height: 15px;">'
							+ '<line x1="0" y1="7" x2="15" y2="7" style="stroke:'
							+ value.color + ';stroke-width:2"/></svg>'
				});
			}
		});
	}
	items.sort(function(a, b){return b.dValue - a.dValue});
	return $scope.mapBuilder.buildLegendTableBody(items);
};

function doOnCurrentEventHashChanged($scope, name) {
	for(var i = 0; i < $scope.eventLocations.length; i++) {
		var location = $scope.eventLocations[i];
		if(location.name === name) {
			$scope.eventLocation = location;
			updateEventInfoControl($scope);
			updatePGVLegendControl($scope, location.pgvCirclesData);
			drawCircles($scope, location.pgvCirclesData);
			updateStationMarkers($scope);
			break;
		}
	}
};

function onCurrentEventHashChanged($scope, name) {
	if($scope.eventLocationsLoaded) {
		doOnCurrentEventHashChanged($scope, name)
	}
	else {
		setTimeout(function() {
			onCurrentEventHashChanged($scope, name);
		}, 100);
	}
};

/**
 * Updates the event information control (bottom right)
 */
function updateEventInfoControl($scope) {
	var label = "";
	if($scope.eventLocation) {
		label = formatLine("Origin time", formatDate($scope.eventLocation.date));
		
		var medianVelocity, upperBoundVelocity;
		if($scope.eventLocation.rHypVelocities) {
			$scope.eventLocation.rHypVelocities.forEach(function(rHypVelocity) {
				if(rHypVelocity.sigma == $scope.sigmaMedian) {
					medianVelocity = rHypVelocity.value;
				}
				else if(rHypVelocity.sigma == $scope.sigmaUpperBound) {
					upperBoundVelocity = rHypVelocity.value;
				}
			});
		}
		if(medianVelocity) {
			label += formatLine("V<sub>Median</sub>", medianVelocity.toExponential(3) + " mm/s");
		}
		if(upperBoundVelocity) {
			label += formatLine("V<sub>Upper bound</sub>", upperBoundVelocity.toExponential(3) + " mm/s");
		}
		if($scope.eventLocation.stationVelocities && !isTouchScreen) { // too big for touch screens
			$scope.eventLocation.stationVelocities.forEach(function(stationVelocity) {
				label += formatLine(stationVelocity.stationName, stationVelocity.velocity.toExponential(3) + " mm/s");
			});
		}
	}
	document.getElementById("eventInfoTable").innerHTML = label;
};

function formatLine(description, value) {
	return "<tr><td>" + description + "\u2003</td><td>" + value + "</td></tr>";
};

/**
 * Updates the peak ground velocity legend
 */
function updatePGVLegendControl($scope, circlesData) {
	document.getElementById("pgvLegendTable").innerHTML = buildHtmlPGVLegendBody($scope, circlesData);
};

function drawCircles($scope, circlesData) {
	if($scope.circleMarkers) {
		$scope.circleMarkers.forEach(function(circleMarker){
			$scope.map.removeLayer(circleMarker);
		});
	}
	
	$scope.circleMarkers = [];
	
	var location = $scope.eventLocation;
	if(!isNaN(location.latitude) && !isNaN(location.longitude)) {
		var latLong = new L.LatLng(location.latitude, location.longitude);
		
		// centre point
		var marker = L.circleMarker(latLong, {
			radius: 3,
			stroke: false,
			fill: true,
			fillColor: 'black',
			fillOpacity: 1.
		});
		marker.addTo($scope.map);
		$scope.circleMarkers.push(marker);
		
		// circles
		if($scope.hasPGVConstantValues) {
			circlesData.forEach(function(value) {
				if($scope.sigma == value.sigma) {
					var tooltip = '<table>'
							+ formatLine('Distance', value.radius.toFixed(1) + ' m')
					 		+ formatLine('Velocity', value.value.toExponential() + ' mm/s')
					 		+ '</table>';
					
					var marker = L.circle(latLong, {
						radius: value.radius,
						color: value.color,
						fill: false
					})
					.bindTooltip(tooltip)
					.bindPopup(tooltip)
					.addTo($scope.map);
					
					$scope.circleMarkers.push(marker);
				}
			});
		}
	}
};

function updateStationMarkers($scope) {
	$scope.stationLocations.forEach(function(stationLocation) {
		var popup = '<text><b>Station name</b> ' + stationLocation.name;
		
		var velocity = getStationVelocity($scope.eventLocation.stationVelocities, stationLocation.name);
		if(velocity) {
			popup += '<br><b>V<sub>MAX</sub></b> ' + velocity.velocity.toExponential(3) + ' mm/s';
		}
		
		popup += '</text>';
		
		stationLocation.marker.bindPopup(popup);
	});
};

