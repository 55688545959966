var app = angular.module('msgWeb.controllers.EventHistory', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function setupEventHistoryPlot($scope) {
	// Draw the chart
	$scope.chart.setOption({
		tooltip: {
			formatter: function(params) {
				return `
                Depth: ${params.data[2]}<br />
                Value: ${params.data[1]}
                `;
			}
		},
		dataZoom: [
			{
				type: 'slider',
				bottom: 60,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid: {
			left: $scope.showSeismicMoment ? 100 : 75,
			top: 10,
			right: 50,
			bottom: 140
		},
		xAxis: {
			name: "Event Time",
			type: 'time',
			nameLocation: 'middle',
			axisLabel: {
				formatter: {
					month: '{MMM}',
					day: '{d} {MMM}',
					hour: '{d}/{MM} {HH}:{mm}',
				}
			},
			nameGap: 30,
			min: $scope.timeMin,
			max: $scope.timeMax
		},
		yAxis: {
			name: $scope.showSeismicMoment ? "Sum Of Seismic Moments" : "Number Of Events",
			nameLocation: 'middle',
			nameGap: $scope.showSeismicMoment ? 75 : 50,
			min: 0,
			max: $scope.max,
			axisLabel: {
				formatter: $scope.showSeismicMoment ? function(value) {
					return value.toExponential();
				} : undefined
			}
		},
		series: {
			type: 'bar',
			data: $scope.data,
			barCategoryGap: '0%',
			itemStyle: {
				color: $scope.colors.length == 0 ?
					'#5470c6' // default color
					: function(param) {
						return $scope.colors[param.dataIndex];
					}
			}
		},
		useUTC: true,
		animation: false
	});
}

function buildEventHistoryData(events, $scope, Settings, ChartBuilder) {
	var sums = {};
	var counts = {};
	var currentBinSizeType = $scope.currentBinSizeType;
	var showTrafficLightColors = $scope.showSeismicMoment && $scope.showTrafficLightColors;

	$scope.data = [];
	$scope.colors = [];
	$scope.max = 0;
	$scope.timeMin = 0;
	$scope.timeMax = 100;

	var green = Settings.getSetting('greenColor');
	var red = Settings.getSetting('redColor');
	var amber = Settings.getSetting('amberColor');

	var trafficLights, isSumPerHour;
	if (showTrafficLightColors) {
		isSumPerHour = $scope.trafficLightMode === 'Sum per hour';
		var criterion = Settings.getSetting(isSumPerHour ? 'seismicMomentSumCriterion' : 'seismicMomentReleaseRateCriterion');
		trafficLights = ChartBuilder.createTrafficLight(criterion, 'lessThan');
	}

	if (events.length) {
		var time = currentBinSizeType.toDate(events[0].date).getTime();
		var min = time, max = time, sum, count;
		events.forEach(function(event) {
			var time = currentBinSizeType.toDate(event.date).getTime();

			count = counts[time];
			counts[time] = (count ? count : 0) + 1;

			sum = sums[time];
			sums[time] = (sum ? sum : 0) + event.seismicMoment;

			if (min > time) {
				min = time;
			}
			if (max < time) {
				max = time;
			}
		});

		var date = new Date(min), next, middle, tooltip, value, trafficLightValue;
		time = date.getTime();
		$scope.timeMin = date;

		while (time <= max) {
			count = counts[time];
			sum = sums[time];

			count = count ? count : 0;
			sum = sum ? sum : 0;
			value = showTrafficLightColors ? sum : count;

			next = currentBinSizeType.toNext(date);
			middle = new Date((time + next.getTime()) * 0.5);
			tooltip = formatDate(date) + " to " + formatDate(next);

			$scope.data.push([middle, value, tooltip]);

			if (showTrafficLightColors) {
				if (isSumPerHour) {
					trafficLightValue = sum / ((next.getTime() - time) / (60 * 60 * 1000));
				}
				else {
					trafficLightValue = count == 0 ? 0 : (sum / count);
				}

				if (trafficLights.isGreen(trafficLightValue)) {
					$scope.colors.push(green);
				}
				else if (trafficLights.isAmber(trafficLightValue)) {
					$scope.colors.push(amber);
				}
				else {
					$scope.colors.push(red);
				}
			}

			date = next;
			time = date.getTime();
			if ($scope.max < value) {
				$scope.max = value;
			}
		}
		$scope.timeMax = date;

		$scope.max = niceNum($scope.max, false);
	}
};

var globBinSize, globShowSeismicMoment, globTrafficLightColors, globTrafficLightMode;

app.controller("eventHistoryController", ['$scope', '$timeout', 'DataModel', 'Settings', 'ChartBuilder',
	function($scope, $timeout, DataModel, Settings, ChartBuilder) {
		$scope.loading = true;
		$scope.canShowSeismicMoment = config.displaySeismicMoment;
		$scope.showSeismicMoment = false;
		$scope.canShowTrafficLights = false;
		$scope.showTrafficLightColors = false;
		$scope.hasSeveralModes = false;
		$scope.trafficLightModes = ['Sum per hour', 'Average'];

		$scope.binSizeTypes = binSizeTypes;
		$scope.currentBinSizeType = binSizeTypes[defaultBinSize];

		$scope.updateDisplay = function() {
			globBinSize = $scope.currentBinSizeType;
			globShowSeismicMoment = $scope.showSeismicMoment;
			globTrafficLightColors = $scope.showTrafficLightColors;
			globTrafficLightMode = $scope.trafficLightMode;

			DataModel.getEvents(function(events) {
				buildEventHistoryData(events, $scope, Settings, ChartBuilder);
				setupEventHistoryPlot($scope);
			});
		};

		if (globBinSize) {
			$scope.currentBinSizeType = globBinSize;
		}
		if (globShowSeismicMoment) {
			$scope.showSeismicMoment = globShowSeismicMoment;
		}
		if (globTrafficLightColors) {
			$scope.showTrafficLightColors = globTrafficLightColors;
		}
		if (globTrafficLightMode) {
			$scope.trafficLightMode = globTrafficLightMode;
		}

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			DataModel.getEvents(function(events) {
				buildEventHistoryData(events, $scope, Settings, ChartBuilder);
				$scope.chart = echarts.init(document.getElementById('chart-event-history'));
				window.addEventListener('resize', function() {
					$scope.chart.resize();
				});
				setupEventHistoryPlot($scope);

				$timeout(function() {
					$scope.loading = false;
				});
			});

			Settings.onSettingsLoaded(function() {
				var sumCriterion = Settings.getSetting('seismicMomentSumCriterion');
				var averageCriterion = Settings.getSetting('seismicMomentReleaseRateCriterion');
				$scope.canShowTrafficLights = sumCriterion || averageCriterion;
				$scope.hasSeveralModes = sumCriterion && averageCriterion;
				if (sumCriterion) {
					$scope.trafficLightMode = $scope.trafficLightModes[0];
				}
				else if (averageCriterion) {
					$scope.trafficLightMode = $scope.trafficLightModes[1];
				}
			});
		}, 10);

		// events coming from the data model
		$scope.$on('eventDataChanged', function(e, events) {
			buildEventHistoryData(events, $scope, Settings, ChartBuilder);
			setupEventHistoryPlot($scope);
		});
	}]);
