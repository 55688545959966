/**
 * service which setup a bar chart: its size and some default settings
 */
angular.module('chartService', []).factory('ChartBuilder', ['Settings', function(Settings){
	
	// we load the traffic light colours
	var green, red, amber;
	Settings.onSettingsLoaded(function() {
		green = Settings.getSetting('greenColor');
		red = Settings.getSetting('redColor');
		amber = Settings.getSetting('amberColor');
	});
	
	/**
	 * calculates the size of the chart
	 */
	function setChartDimension(chart, elementId) {
		var element = document.getElementById(elementId);
		var bottomBarElements = document.getElementsByClassName('navbar navbar-app navbar-absolute-bottom');
		var bottomBarElement;
		if(bottomBarElements) {
			for (var i = 0; i < bottomBarElements.length; i++) {
				if(bottomBarElements[i].offsetHeight > 0) { // check is visible
					bottomBarElement = bottomBarElements[i];
					break;
				}
			}
		}
		
		if(element) {
			var widthOffset = 0, heightOffset = 0;
			while (element.offsetParent && element.offsetParent.id!="wrapper")
			{
				widthOffset += element.offsetTop;
				heightOffset += element.offsetLeft;
			    element = element.offsetParent;
			}
			var chartHeight = element.offsetHeight - widthOffset;
			var chartWidth = element.offsetWidth - heightOffset;
			if(bottomBarElement) {
				chartHeight -= bottomBarElement.offsetHeight;
			}
			if(chart.height() != chartHeight || chart.width() != chartWidth) {
				chart.height(chartHeight).width(chartWidth);
				return true;
			}
		}
		return false;
	};
	
	/**
	 * Re-renders the chart after its dimensions have changed
	 */
	function reRenderChart(chart) {
		var current = chart.transitionDuration();
		chart.transitionDuration(0);
		chart.render();
		chart.transitionDuration(current);
	};
	
	return {
		/**
		 * Sets common settings to a chart
		 */
		setupChart: function(chart, elementId) {
			chart
			.margins({top: 10, right: 50, bottom: 40, left: 70})
			.width(768)
			.height(480)
			.title(function(p) {return null;})// disable tooltips
			.renderTitle(false)
			.brushOn(false)
			.mouseZoomable(true)
			.zoomOutRestrict(false)
			.transitionDuration(300)
			
			.on("preRender", function(chart){
				setChartDimension(chart, elementId);
				chart.rescale();
			})
			.on("postRender", function(chart){
				setTimeout(function() {
					if(setChartDimension(chart, elementId)) {
						reRenderChart(chart);
					}
				}, 100);
			})
			.on("preRedraw", function (chart) {
		        chart.rescale();
		    });
			
			window.onresize = function(event) {
				reRenderChart(chart);
			};
		},
		
		/**
		 * Sets common settings to a bar chart
		 */
		setupBarChart: function(chart, elementId) {
			this.setupChart(chart, elementId);
			chart.gap(0);
		},
		
		/**
		 * Creates a traffic light object from a criterion and a test type ('moreThan' or 'lessThan')
		 */
		createTrafficLight: function(criterion, test) {
			switch(test) {
			case 'moreThan':
				return {
				isGreen: function(d) {
					return d > criterion.greenAmberValue;
				},

				isAmber: function(d) {
					return d > criterion.amberRedValue;
				},
				
				zoneIntervals:
					[{ //red interval
						min: Number.MAX_VALUE,
						max: criterion.amberRedValue,
						color: red
					},
					{ //amber interval
						min: criterion.amberRedValue,
						max: criterion.greenAmberValue,
						color: amber
					},
					{ //green interval
						min: criterion.greenAmberValue,
						max: Number.MAX_VALUE,
						color: green
					}]
			};

			case 'lessThan':
				return {
				isGreen: function(d) {
					return d < criterion.greenAmberValue;
				},

				isAmber: function(d) {
					return d < criterion.amberRedValue;
				},
				
				zoneIntervals:
					[{ //green interval
						min: -Number.MAX_VALUE,
						max: criterion.greenAmberValue,
						color: green
					},
					{ //amber interval
						min: criterion.greenAmberValue,
						max: criterion.amberRedValue,
						color: amber
					},
					{ //red interval
						min: criterion.amberRedValue,
						max: Number.MAX_VALUE,
						color: red
					}]
			};
			}
		},
		
		/**
		 * Combines two traffic lights into one
		 */
		combine: function(trafficLight1, trafficLight2) {
			var intersection = function(interval1, interval2) {
				return { //green interval
					min: Math.max(interval1.min, interval2.min),
					max: Math.min(interval1.max, interval2.max),
					color: interval1.color
				};
			};
			
			var union = function(interval1, interval2) {
				return { //green interval
					min: Math.min(interval1.min, interval2.min),
					max: Math.max(interval1.max, interval2.max),
					color: interval1.color
				};
			};
			
			function exists(interval) {
				return interval.min < interval.max;
			};
			
			var zoneIntervals1 = trafficLight1.zoneIntervals;
			var zoneIntervals2 = trafficLight2.zoneIntervals;
			var allIntervals = zoneIntervals1.concat(zoneIntervals2);
			var zoneIntervals = [];
			
			/*
			 * Green interval
			 */
			var greenInterval;
			allIntervals.forEach(function(interval) {
				if(interval.color == green) {
					if(greenInterval) {
						greenInterval = intersection(greenInterval, interval);
					}
					else {
						greenInterval = interval;
					}
				}
			});
			if(greenInterval && exists(greenInterval)) {
				zoneIntervals.push(greenInterval);
			}
			
			/*
			 * Amber and red intervals
			 */
			var amberInterval;
			allIntervals.forEach(function(interval) {
				if(interval.color == amber) {
					if(amberInterval) {
						amberInterval = union(amberInterval, interval);
					}
					else {
						amberInterval = interval;
					}
				}
			});
			// before green
			var beforeInterval = { min: -Number.MAX_VALUE, max: greenInterval.min };
			if(exists(beforeInterval)) {
				if(amberInterval && exists(amberInterval)) {
					var interval = intersection(amberInterval, beforeInterval);
					if(exists(interval)) {
						zoneIntervals.push(interval);
						beforeInterval.max = interval.min;
					}
				}
				if(exists(beforeInterval)) {
					beforeInterval.color = red;
					zoneIntervals.push(beforeInterval);
				}
			}
			
			// after green
			var afterInterval = { min: greenInterval.max, max: Number.MAX_VALUE };
			if(exists(afterInterval)) {
				if(amberInterval && exists(amberInterval)) {
					var interval = intersection(amberInterval, afterInterval);
					if(exists(interval)) {
						zoneIntervals.push(interval);
						afterInterval.min = interval.max;
					}
				}
				if(exists(afterInterval)) {
					afterInterval.color = red;
					zoneIntervals.push(afterInterval);
				}
			}
			
			return {
				isGreen: function(d) {
					return trafficLight1.isGreen(d) && trafficLight2.isGreen(d);
				},

				isAmber: function(d) {
					return trafficLight1.isAmber(d) && trafficLight2.isAmber(d);
				},
				
				zoneIntervals: zoneIntervals
			};
		},
		
		/**
		 * Creates a colour calculator based on traffic light
		 */
		getCriterionColorCalculator: function(trafficLight) {			
			return function (d){
		    	if(trafficLight.isGreen(d)) {
		    		return green;
		    	}
		    	if(trafficLight.isAmber(d)) {
		    		return amber;
		    	}
		    	return red;
		    };
		}
	};
}]);
