var onLoggedOut;
var socketIO;

var modules = [
  'ngRoute',
  'mobile-angular-ui',
  'mobile-angular-ui.gestures',
  'authenticationService',
  'dataService',
  'settingsService',
  'chartService',
  'mapService',
  'msgWeb.controllers.Login',
  'msgWeb.controllers.Main',
  'msgWeb.controllers.Settings',
  'msgWeb.controllers.Catalogue',
  'msgWeb.controllers.Map',
  'msgWeb.controllers.3dView',
  'msgWeb.controllers.EventHistory',
  'msgWeb.controllers.EventDepth',
  'msgWeb.controllers.triggerHistory',
  'msgWeb.controllers.triggerTypeHistory',
  'msgWeb.controllers.triggerType',
  'msgWeb.controllers.timeMagnitude',
  'msgWeb.controllers.depthMagnitude',
  'msgWeb.controllers.peakVelocity',
  'msgWeb.controllers.peakGroundVelocityMap',
  'msgWeb.controllers.vMaxTimeHistory',
  'msgWeb.controllers.vMaxTriggerHistory',
  'msgWeb.controllers.vMaxMagnitude'
];

angular.module('msgWeb', modules).config(function($routeProvider, $httpProvider) {
	
  $routeProvider
  .when('/',        				{templateUrl: 'map.html', reloadOnSearch: false})
  .when('/settings',  				{templateUrl: 'settings.html', reloadOnSearch: false})
  .when('/catalogue',  				{templateUrl: 'catalogue.html', reloadOnSearch: false})
  .when('/map',    					{templateUrl: 'map.html', reloadOnSearch: false})
  .when('/3dView',    				{templateUrl: '3dView.html', reloadOnSearch: false})
  .when('/eventHistory',			{templateUrl: 'eventHistory.html', reloadOnSearch: false})
  .when('/eventDepth',   			{templateUrl: 'eventDepth.html', reloadOnSearch: false})
  .when('/triggerHistory',   		{templateUrl: 'triggerHistory.html', reloadOnSearch: false})
  .when('/triggerTypeHistory',   	{templateUrl: 'triggerTypeHistory.html', reloadOnSearch: false})
  .when('/triggerType',   			{templateUrl: 'triggerType.html', reloadOnSearch: false})
  .when('/timeMagnitude',   		{templateUrl: 'timeMagnitude.html', reloadOnSearch: false})
  .when('/depthMagnitude',   		{templateUrl: 'depthMagnitude.html', reloadOnSearch: false})
  .when('/peakVelocity',   			{templateUrl: 'peakVelocity.html', reloadOnSearch: false})
  .when('/peakGroundVelocityMap', 	{templateUrl: 'peakGroundVelocityMap.html', reloadOnSearch: false})
  .when('/vMaxTimeHistory', 		{templateUrl: 'vMaxTimeHistory.html', reloadOnSearch: false})
  .when('/vMaxTriggerHistory', 		{templateUrl: 'vMaxTriggerHistory.html', reloadOnSearch: false})
  .when('/vMaxMagnitude', 			{templateUrl: 'vMaxMagnitude.html', reloadOnSearch: false})
  .otherwise({ redirectTo: '/' });
  
  //disable ajax request caching (for IE and Edge)
  if (!$httpProvider.defaults.headers.get) {
	  $httpProvider.defaults.headers.get = {};    
  }    
  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
  
  if(config.useLogin) {
	  $httpProvider.defaults.withCredentials = true
	  $routeProvider.when('/login', {templateUrl: 'login.html', reloadOnSearch: false});
	  
	  /*
	   * We intercept here unauthorised errors from the server.
	   * We this happens, we consider the user as logged out. 
	   */
	  $httpProvider.interceptors.push(function($q, $location) {
		  return {
			  responseError: function(response) {
				  if (response.status === 401 && onLoggedOut) {
					  onLoggedOut();
				  }
				  return $q.reject(response);
			  }
		  };
	  });
  }
})

// "Settings" added here to force it to be loaded from the beginning
.run(['$rootScope', '$timeout', 'Settings', '$location',
      function($rootScope, $timeout, Settings, $location) {
	
	$rootScope.title = 'MsgWeb';	
	$rootScope.useLogin = config.useLogin; // Logout button visibility
	$rootScope.originalPath = $location.path();
	if(!$rootScope.originalPath) {
		$rootScope.originalPath = '/';
	}
	
	/*
	 * Connect to the IO server to get messages from the server and broadcast them to other services
	 */
	function connectSocketIO() {
		if(!socketIO) {
			socketIO = io.connect(location.host, {'forceNew': true});
			socketIO.on("dataChange", function(message) {
				setTimeout(function() {
					$rootScope.$broadcast("dataChange", message);
				}, 60000); // we wait a minute in case there is data caching on the server side
			});
		}
	}
	
	/*
	 * Disconnect from IO server
	 */
	function disconnectSocketIO() {
		if(socketIO) {
			socketIO.disconnect();
			socketIO = null;
		}
	}
	
	/*
	 * Function which display a waiting animation.
	 * As long as all the data hasn't been loaded, it is displayed, but also sometimes when loading new page
	 */
	function checkLoadingData() {
		var dataLoaded = settingsLoaded = false;
		
		var updateLoadingState = function(loading) {
			$timeout(function() {
				$rootScope.loading = loading;
			});
		};
		
		updateLoadingState(true);
		
		$rootScope.$on('dataLoaded', function() { // when the data is loaded
			dataLoaded = true;
			updateLoadingState(!dataLoaded || !settingsLoaded);
		});
		$rootScope.$on('settingsLoaded', function() { // when the settings are loaded
			settingsLoaded = true;
			updateLoadingState(!dataLoaded || !settingsLoaded);
		});
	};
	
	/*
	 * When running without login the data is loaded directly
	 * while with login we need to wait until the user is logged in
	 */
	if(config.useLogin) {
		
		// logging in
		$rootScope.loggedIn = false;	// not logged in yet
		$location.url('/login');
		
		/*
		 * Function called when we meet an unauthorised error from the server
		 */
		onLoggedOut = function() {
			$rootScope.loggedIn = false;
			$rootScope.$broadcast('loggedIn', false);
			$location.url('/login'); 
		};
		
		$rootScope.$on( "$routeChangeStart", function(event, next, current) {
			if ($rootScope.username === "q-con"
				&& (next.templateUrl === 'peakGroundVelocityMap.html' || next.templateUrl === 'peakVelocity.html')) {
				$location.path("/");
			}
		});
		
		/*
		 * Event telling us when the user is logged in or out
		 */
		$rootScope.$on('loggedIn', function(event, loggedIn) {
			if(loggedIn) {
				connectSocketIO();
				checkLoadingData();
				$timeout(function() {
					$rootScope.title = config.title;
				});
			}
			else {
				disconnectSocketIO();
				$timeout(function() {
					$rootScope.loading = false;
					$rootScope.title = 'MsgWeb';
				});
			}
		});
		
		/*
		 * When not logged in we force the user to stay on the login page
		 */
		$rootScope.$on( "$routeChangeStart", function(event, next, current) {
			if ($rootScope.loggedIn) {
				if (next.templateUrl === 'login.html') {
					$location.path("/");
				}
			}
			else if (next.templateUrl !== 'login.html') {
				$location.path("/login");
			}
		});
	}
	else {
		$rootScope.title = config.title;
		connectSocketIO();
		checkLoadingData();
	}
}]);
