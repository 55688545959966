var app = angular.module('msgWeb.controllers.EventDepth', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function setupEventDepthPlot($scope) {
	// Draw the chart
	$scope.chart.setOption({
		tooltip: {
			formatter: (params) => {
				return `
                Depth: ${params.data[2]}<br />
                Count: ${params.data[1]}
                `;
			}
		},
		dataZoom: [
			{
				type: 'slider',
				bottom: 60,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid: {
			left: 75,
			top: 10,
			right: 50,
			bottom: 140
		},
		xAxis: {
			name: "Event Depth (m)",
			nameLocation: 'middle',
			nameGap: 30,
			min: $scope.minDepth,
			max: $scope.maxDepth,
			dimensions: ['Depth', 'Count', 'Range'],
		},
		yAxis: {
			name: "Number Of Events",
			nameLocation: 'middle',
			nameGap: 50,
			min: 0,
			max: $scope.max
		},
		series: [
			{
				type: 'bar',
				data: $scope.data,
				barCategoryGap: '0%'
			}
		],
		useUTC: true,
		animation: false
	});
}

function getDepthLength(events) {
	if (events.length) {
		var depth = events[0].z;
		var min = depth, max = depth;
		events.forEach(function(event) {
			depth = event.z;
			if (min > depth) {
				min = depth;
			}
			if (max < depth) {
				max = depth;
			}
		});
		return max - min;
	}
	return 100;
};

function buildEventDepthData(events, $scope) {
	$scope.data = [];
	$scope.max = 0;
	$scope.minDepth = 0;
	$scope.maxDepth = 100;

	if (events.length) {
		var depth = events[0].z;
		var min = depth, max = depth;
		events.forEach(function(event) {
			depth = event.z;
			if (min > depth) {
				min = depth;
			}
			if (max < depth) {
				max = depth;
			}
		});
		if (min < 0) {
			min = -niceNum(-min, false);
		}
		else {
			min = niceNum(min, false);
		}

		var intervalCount = Math.floor((max - min) / $scope.currentBinSize);

		if (intervalCount * $scope.currentBinSize !== max - min) {
			max = (intervalCount + 1) * $scope.currentBinSize + min;
		}

		$scope.minDepth = min;
		$scope.maxDepth = max;

		var depth = min, next;
		while (depth <= max) {
			next = depth + $scope.currentBinSize;
			$scope.data.push([depth + $scope.currentBinSize * 0.5, 0, depth + " to " + next]);
			depth = next;
		}

		events.forEach(function(event) {
			$scope.data[Math.floor((event.z - min) / $scope.currentBinSize)][1]++;
		});

		$scope.data.forEach(function(value) {
			if ($scope.max < value[1]) {
				$scope.max = value[1];
			}
		});
		$scope.max = niceNum($scope.max, false);
	}
};

app.controller("eventDepthController", ['$scope', '$timeout', 'DataModel',
	function($scope, $timeout, DataModel) {
		$scope.loading = true;

		if (typeof config.eventDepthBinSize === 'number') {
			$scope.currentBinSize = config.eventDepthBinSize;
		}

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			DataModel.getEvents(function(events) {
				buildEventDepthData(events, $scope);

				if (!$scope.currentBinSize) {
					$scope.currentBinSize = Math.round(getDepthLength(events) / 20.);
				}
				if ($scope.currentBinSize <= 0) {
					$scope.currentBinSize = 1;
				}

				$scope.chart = echarts.init(document.getElementById('chart-event-depth'));
				window.addEventListener('resize', function() {
					$scope.chart.resize();
				});
				setupEventDepthPlot($scope);

				$timeout(function() {
					$scope.loading = false;
				});
			});
		}, 10);

		$scope.onBinSizeChanged = function() {
			DataModel.getEvents(function(events) {
				if ($scope.currentBinSize) {
					buildEventDepthData(events, $scope);
					setupEventDepthPlot($scope);
				}
			});
		};

		// events coming from the data model
		$scope.$on('eventDataChanged', function(e, events) {
			buildEventDepthData(events, $scope);
			setupEventDepthPlot($scope);
		});
	}]);