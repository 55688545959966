var app = angular.module('msgWeb.controllers.vMaxMagnitude', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

var setupVMaxMagnitudeBarChart = function($scope, Settings) {
	var markData = [{
		lineStyle: {
			type: "solid",
			color: 'gray',
			width: 1
		},
		xAxis: 0
	},
	{
		lineStyle: {
			type: "solid",
			color: 'gray',
			width: 1
		},
		yAxis: 1
	}];

	var peakVelocityCriterion = Settings.getSetting('peakVelocityCriterion');
	if (peakVelocityCriterion) {
		var redThreshold = peakVelocityCriterion.amberRedValue;
		var amberThreshold = peakVelocityCriterion.greenAmberValue;

		markData.push({
			lineStyle: {
				type: "dashed",
				color: 'red',
				width: 3
			},
			yAxis: redThreshold
		});
		markData.push({
			lineStyle: {
				type: "dashed",
				color: '#FFC400',
				width: 3
			},
			yAxis: amberThreshold
		});
	}

	var magnitudeCriterion = Settings.getSetting('magnitudeCriterion');
	if (magnitudeCriterion) {
		var redThreshold = magnitudeCriterion.amberRedValue;
		var amberThreshold = magnitudeCriterion.greenAmberValue;

		markData.push({
			lineStyle: {
				type: "dashed",
				color: 'red',
				width: 3
			},
			xAxis: redThreshold
		});
		markData.push({
			lineStyle: {
				type: "dashed",
				color: '#FFC400',
				width: 3
			},
			xAxis: amberThreshold
		});
	}

	// Draw the chart
	$scope.chart.setOption({
		legend: {
			show: true,
			orient: 'vertical',
			left: 70,
			top: 30,
			backgroundColor: 'white',
			z: 10, // on top of everything
			borderColor: '#333',
			borderWidth: 0.8,
		},
		dataZoom: [
			{
				type: 'slider',
				bottom: 20,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'slider',
				yAxisIndex: 0,
				right: 10,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid: {
			left: 50,
			top: 20,
			right: 70,
			bottom: 90
		},
		xAxis: {
			name: config.magnitudeLabel,
			nameLocation: 'middle',
			axisLine: { onZero: false },
			nameGap: 20
		},
		yAxis: {
			name: "Peak Velocity (mm/s)",
			type: 'log',
			nameLocation: 'middle',
			axisLine: { onZero: false },
			min: 0.001,
			max: 10,
			nameGap: 30
		},
		series: [
			{
				name: config.projectName,
				type: 'scatter',
				symbolSize: 5,
				symbol: 'circle',
				color: 'green',
				data: $scope.data[0],
				markLine: {
					symbol: 'none',
					data: markData
				}
			},
			{
				name: config.historicalProjectName,
				type: 'scatter',
				symbolSize: 5,
				symbol: 'circle',
				color: 'black',
				data: $scope.data[1]
			}],
		useUTC: true,
		animation: false
	});
}

function buildVMaxMagnitudeData(dataSet, $scope) {
	$scope.data = [];
	$scope.maxMagnitude = -Number.MAX_VALUE;
	$scope.minMagnitude = Number.MAX_VALUE;
	var currentData = [];
	var historicalData = [];

	dataSet.sort(function(a, b) {
		return a.date.getTime() - b.date.getTime();
	});

	var d, array;
	for (var i = 0; i < dataSet.length; i++) {
		d = dataSet[i];

		if (d.triggerType === 'microseis' && d.magnitude) {

			array = d.project === config.historicalProjectName ? historicalData : currentData
			array.push([d.magnitude, d.maxVelocity]);

			if ($scope.maxMagnitude < d.magnitude) {
				$scope.maxMagnitude = d.magnitude;
			}
			if ($scope.minMagnitude > d.magnitude) {
				$scope.minMagnitude = d.magnitude;
			}
		}
	}

	$scope.data.push(currentData);
	$scope.data.push(historicalData);
};

app.controller("vMaxMagnitudeController", ['$scope', 'DataModel', 'Settings', '$timeout',
	function($scope, DataModel, Settings, $timeout) {
		$scope.loading = true;

		// we don't start loading the graph straight away to improve the response speed
		Settings.onSettingsLoaded(function() {
			$timeout(function() {
				DataModel.getCatalogueData(function(dataSet) {
					DataModel.calcMaxVelocity(dataSet, Settings);
					buildVMaxMagnitudeData(dataSet, $scope);
					$scope.chart = echarts.init(document.getElementById('chart-vmax-magnitude'));
					window.addEventListener('resize', function() {
						$scope.chart.resize();
					});
					setupVMaxMagnitudeBarChart($scope, Settings);

					// events coming from the data model
					function listener() {
						DataModel.getCatalogueData(function(dataSet) {
							DataModel.calcMaxVelocity(dataSet, Settings);
							buildVMaxMagnitudeData(dataSet, $scope);
							setupVMaxMagnitudeBarChart($scope, Settings);
						}, true);
					};
					$scope.$on('triggerDataChanged', listener);
					$scope.$on('historicalTriggerDataChanged', listener);
					$scope.$on('eventDataChanged', listener);
					$scope.$on('historicalEventDataChanged', listener);

					$timeout(function() {
						$scope.loading = false;
					});
				}, true);
			}, 10);
		});
	}]);