var app = angular.module('msgWeb.controllers.vMaxTriggerHistory', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

var setupVMaxTriggerHistoryBarChart = function($scope, Settings) {
	var markData = [];

	var peakVelocityCriterion = Settings.getSetting('peakVelocityCriterion');
	if (peakVelocityCriterion) {
		markData.push({
			lineStyle: {
				type: "dashed",
				color: '#FFC400',
				width: 3
			},
			yAxis: peakVelocityCriterion.greenAmberValue
		});
		markData.push({
			lineStyle: {
				type: "dashed",
				color: 'red',
				width: 3
			},
			yAxis: peakVelocityCriterion.amberRedValue
		});
	}

	// Draw the chart
	$scope.chart.setOption({
		dataZoom: [
			{
				type: 'slider',
				bottom: 60,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid: {
			left: 50,
			top: 10,
			right: 50,
			bottom: 130
		},
		xAxis: {
			name: "Event ID",
			nameLocation: 'middle',
			nameGap: 20
		},
		yAxis: {
			name: "Peak Velocity (mm/s)",
			nameLocation: 'middle',
			min: 0,
			max: getYMax($scope, Settings),
			axisLine: { onZero: false },
			nameGap: 30
		},
		series: [
			{
				id: 'vmaxseis',
				type: 'scatter',
				symbolSize: 5,
				symbol: 'circle',
				color: 'black',
				data: $scope.vmaxseis,
				markLine: {
					symbol: 'none',
					data: markData
				}
			},
			{
				id: 'lastvmax',
				type: 'scatter',
				symbolSize: 5,
				symbol: 'diamond',
				color: 'black',
				data: $scope.lastvmax
			},
			{
				id: 'vmaxshot',
				type: 'scatter',
				symbolSize: 5,
				symbol: 'circle',
				color: 'green',
				data: $scope.data.showShots ? $scope.vmaxshot : []
			}],
		useUTC: true,
		animation: false
	});
}

function buildVMaxTriggerHistoryData(dataSet, $scope, Settings) {
	$scope.vmaxseis = [];
	$scope.vmaxshot = [];
	$scope.vmaxmax = 0;

	var vMaxStartTime = null;
	if ($scope.data.filterByTriggerStartTime) {
		vMaxStartTime = Settings.getSetting('velMaxStartTime');
		if (vMaxStartTime) {
			vMaxStartTime = vMaxStartTime.getTime();
		}
	}

	dataSet.sort(function(a, b) {
		return a.date.getTime() - b.date.getTime();
	});

	var trigger, d;
	for (var i = 0; i < dataSet.length; i++) {
		d = dataSet[i];
		trigger = d.triggerID;

		if (!vMaxStartTime || vMaxStartTime < d.date.getTime()) {
			if (d.type === 'microseis') {
				$scope.vmaxseis.push([trigger, d.maxVelocity]);
				if ($scope.vmaxmax < d.maxVelocity) {
					$scope.vmaxmax = d.maxVelocity;
				}
			}
			else if (d.type === 'shot') {
				$scope.vmaxshot.push([trigger, d.maxVelocity]);
			}
		}
	}

	$scope.lastvmax = [$scope.vmaxseis.length == 0 ? null : $scope.vmaxseis.pop()];

	if ($scope.vmaxseis.length == 0) {
		$scope.vmaxseis = [null];
	}
	if ($scope.vmaxshot.length == 0) {
		$scope.vmaxshot = [null];
	}
};

function onVMaxTriggerHistoryDataChanged($scope, DataModel, Settings) {
	DataModel.getTriggers(function(dataSet) {
		DataModel.calcMaxVelocity(dataSet, Settings);
		buildVMaxTriggerHistoryData(dataSet, $scope, Settings);
		setupVMaxTriggerHistoryBarChart($scope, Settings);
	});
};

app.controller("vMaxTriggerHistoryController", ['$scope', 'DataModel', 'Settings', '$timeout',
	function($scope, DataModel, Settings, $timeout) {
		$scope.loading = true;
		$scope.autoZoom = true;
		$scope.data = {
			showShots: false,
			filterByTriggerStartTime: true,
			canFilterByTriggerStartTime: false
		};

		// we don't start loading the graph straight away to improve the response speed
		Settings.onSettingsLoaded(function() {
			$scope.data.canFilterByTriggerStartTime = Settings.hasSetting('velMaxStartTime');
			$timeout(function() {
				DataModel.getTriggers(function(dataSet) {
					DataModel.calcMaxVelocity(dataSet, Settings);
					buildVMaxTriggerHistoryData(dataSet, $scope, Settings);
					$scope.chart = echarts.init(document.getElementById('chart-vmax-trigger-history'));
					window.addEventListener('resize', function() {
						$scope.chart.resize();
					});
					
					setupVMaxTriggerHistoryBarChart($scope, Settings);

					// events coming from the data model
					$scope.$on('triggerDataChanged', function() {
						onVMaxTriggerHistoryDataChanged($scope, DataModel, Settings);
					});

					$timeout(function() {
						$scope.loading = false;
					});
				});
			}, 10);
		});

		$scope.onStartInstantFilterChanged = function() {
			onVMaxTriggerHistoryDataChanged($scope, DataModel, Settings);
		};

		$scope.shotVisibilityChanged = function() {
			onVMaxTriggerHistoryDataChanged($scope, DataModel, Settings);
		};
	}]);