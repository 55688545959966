var app = angular.module('msgWeb.controllers.Login', [
  'ngRoute',
  'mobile-angular-ui',
  'mobile-angular-ui.core'
]);

app.controller('loginController', ['$scope', '$timeout', 'Authentication', 
                                   function($scope, $timeout, Authentication) {
	$scope.loginName = '';
	$scope.password = '';
	$scope.mozilla = navigator.userAgent.indexOf("Firefox") > 0;
	
	$scope.login = function() {
		Authentication.checkAuthentication($scope.loginName, $scope.password, function(errorMessage) {
			if(errorMessage) {
				$timeout(function() {
					$scope.errorMessage = errorMessage;
					$scope.loginName = '';
					$scope.password = '';
				});
			}
		});
	};
}]);
