/**
 * Function to debug CrossFilter
 * @param filter
 */
function print_filter(filter){
	var f=eval(filter);
	if (typeof(f.length) != "undefined") {}else{}
	if (typeof(f.top) != "undefined") {f=f.top(Infinity);}else{}
	if (typeof(f.dimension) != "undefined") {f=f.dimension(function(d) { return "";}).top(Infinity);}else{}
	console.log(filter+"("+f.length+") = "+JSON.stringify(f).replace("[","[\n\t").replace(/}\,/g,"},\n\t").replace("]","\n]"));
}

var analysts = [];
config.analysts.forEach(function(analyst, i) {
	analysts.push({id: '' + i, name: analyst});
});
var currentAnalyst = analysts[0];

var toYearTime = function(date) {
	return new Date(Date.UTC(date.getUTCFullYear()));
};
var toMonthTime = function(date) {
	return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()));
};
var toWeekTime = function(date) {
	return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - date.getUTCDay()));
};
var toDayTime = function(date) {
	return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
};

var toNextYear = function(date) {
	var date = new Date(date.getTime());
	date.setUTCFullYear(date.getUTCFullYear() + 1);
	return date;
};
var toNextMonth = function(date) {
	var date = new Date(date.getTime());
	date.setUTCMonth(date.getUTCMonth() + 1);
	return date;
};
var toNextWeek = function(date) {
	var date = new Date(date.getTime());
	date.setUTCDate(date.getUTCDate() + 7);
	return date;
};
var toNextDay = function(date) {
	var date = new Date(date.getTime());
	date.setUTCDate(date.getUTCDate() + 1);
	return date;
};

var binSizeTypes = [
                    {id: '1', name:'Year', toDate: toYearTime, toNext: toNextYear},
                    {id: '2', name:'Month', toDate: toMonthTime, toNext: toNextMonth},
                    {id: '3', name:'Week', toDate: toWeekTime, toNext: toNextWeek},
                    {id: '4', name:'Day', toDate: toDayTime, toNext: toNextDay}];

/**
 * getting the default bin size
 */
var defaultBinSize = 2; // days
if(typeof config.eventHistoryBinSize === "string") {
	for (var i = 0; i < binSizeTypes.length; i++) {
		if(binSizeTypes[i].name.toUpperCase() === config.eventHistoryBinSize.toUpperCase()) {
			defaultBinSize = i;
			break;
		}
	}
}

var isTouchScreen = false; //initiate as false
//device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
 || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isTouchScreen = true;

var formatInteger2 = function(value) {
	return value < 10 ? "0" + value : "" + value;
};

// format the date in the format yyyy-MM-dd HH:mm:ss
var formatDate = function(date) {
	return "" + date.getUTCFullYear()
		+ "-" + formatInteger2(date.getUTCMonth() + 1)
		+ "-" + formatInteger2(date.getUTCDate())
		+ " " + formatInteger2(date.getUTCHours())
		+ ":" + formatInteger2(date.getUTCMinutes())
		+ ":" + formatInteger2(date.getUTCSeconds())
};

/**
 * This found in the internet and may be useful for making the tick numbers nice like 1.0 0.5 etc rather than 1.23546 0.9987
 * See class NiceScale
 */
function niceNum(range, round) {
	var exponent; /** exponent of range */
	var fraction; /** fractional part of range */
	var niceFraction; /** nice, rounded fraction */

	exponent = Math.floor(Math.log10(range));
	fraction = range / Math.pow(10, exponent);

	if (round) {
		if (fraction < 1.5)
			niceFraction = 1;
		else if (fraction < 3)
			niceFraction = 2;
		else if (fraction < 7)
			niceFraction = 5;
		else
			niceFraction = 10;
	}
	else {
		if (fraction <= 1)
			niceFraction = 1;
		else if (fraction <= 2)
			niceFraction = 2;
		else if (fraction <= 5)
			niceFraction = 5;
		else
			niceFraction = 10;
	}

	return niceFraction * Math.pow(10, exponent);
};
