/**
 * File which defines the settings to be defined by the admin.
 * Do not change the structure of this file, just change the values when needed.
 */
var config = {
 		/**
 		 * Global (can be any string)
 		 */
		title:					'Eden Geothermal',

		/**
		 * Server configuration
		 */
		host: 					'0.0.0.0',
		port: 					443,
		https: 					true,
		passPhrase:				'chkEyPYmsPasashrcreqgH',
		passPhrase_encrypted:	true,
		key: 					'/home/altcom/msgWeb/certificate/server-key.pem',
		certificate:			'/home/altcom/msgWeb/certificate/server-cert.pem',
		pfx:					null,

		/**
		 * MongoDB host and port
		 */
		mongo_host: 			'localhost',
		mongo_port: 			27017,
		mongo_ssl: 				true,
		mongo_authenticate: 	true,
		mongo_user: 			'msg-user',
		mongo_psw: 				'mu7ThFkSdjlsL8JKcr73ok',
		mongo_psw_encrypted: 	true,
		mongo_authDB: 			'user',

		/**
		 * Name of the MongoDB database
		 */
		dbName: 				'statistics',

		/**
		 * Project name, i.e name of the Derby database
		 */
		projectName: 				'Eden5',
		showHistoricalTriggers: 	true,
		historicalProjectName: 		'Eden4',

		/**
		 * User and analyst names in the Derby DB
		 */
		user: 					'EGL_USER',
		analysts:				['MANUAL', 'AUTO'],

		/**
		 * Security
		 */
		useLogin: 				true,
		secret: 				'altcom',

		/**
		 * Bin sizes optional default values.
		 * Must be minute, hour, day, week, month or year for the event history chart (case is ignored)
		 * and a value (in meters) higher that 0 for the event depth chart.
		 */
		eventHistoryBinSize:	'week',
		eventDepthBinSize:		300,

		useLiveReload:			false, // true when debugging

		/**
		 * The folder to the snapshot images
		 */
		snapshotPath: 			'/home/altcom/data/msgServer/snapshots/',

		/**
		 * Magnitude parameters
		 */
		 getMagnitude:				function(event) {
		 								return event.localMagnitude;	//return event.momentMagnitude;
		 							},
		 magnitudeSymbol:			'M<sub><small>L</small></sub>',		//'M<sub><small>W</small></sub>'
		 magnitudeLabel:			'Local Magnitude',		//'Moment Magnitude'
		 
		 /**
		 * Update folder for notifications from MsgServer
		 */
		 updateFolder:					'/home/altcom/serverApps/msgServer/msgWebNotification',
};
