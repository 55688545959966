var app = angular.module('msgWeb.controllers.triggerHistory', [
  'ngRoute',
  'mobile-angular-ui',
  'mobile-angular-ui.core'
]);

var setupTriggerHistoryBarChart = function($scope) {
	// Draw the chart
	$scope.chart.setOption({
		legend: {
			show: true,
			orient: 'vertical',
			left: 100,
			top: 30,
			backgroundColor: 'white',
			z: 10, // on top of everything
			borderColor: '#333',
        	borderWidth: 0.8,
		},
		dataZoom: [
			{
				type: 'slider',
				bottom: 40,
				dataBackground:{
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground:{
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid:{
			left: 75,
			top: 10,
			right: 50,
			bottom: 120
		},
		xAxis: {
			name: "Trigger Time",
			type: 'time',
			nameLocation: 'middle',
			axisLabel: {
				formatter: {
					month: '{MMM}',
					day: '{d} {MMM}',
    				hour: '{d}/{MM} {HH}:{mm}',
				}
			},
			nameGap: 30
		},
		yAxis: {
			name: "Cumulative Count",
			nameLocation: 'middle',
			axisLine: {
				show: true
			},
			min: 0,
			nameGap: 50
		},
		series: [
			{
				name: 'Located',
				type: 'scatter',
				data: $scope.locatedData,
				color: '#47ff49',
				symbolSize: 12,
				large: true
		    },
			{
				name: 'Triggers',
				type: 'scatter',
				data: $scope.triggerData,
				color: '#919191',
				symbolSize: 8,
				large: true
		    }
		],
		useUTC: true,
		animation: false
	});
}

function buildTriggerHistoryData(dataSet, $scope) {
	$scope.triggerData = [];
	$scope.locatedData = [];
	$scope.max = 0;
	
	dataSet.sort(function(a, b) {
		return a.triggerID - b.triggerID;
	});
	
	var time, d;
	var nLocated = 0;
	for (var i = 0; i < dataSet.length; i++) {
		d = dataSet[i];
		time = d.date.getTime();
		if(time > $scope.max) {
			$scope.max = time;
		}
		if(time != 0 && d.x) { // check has event
			nLocated++;
		}
		$scope.triggerData.push([time, d.triggerID]);
		$scope.locatedData.push([time, nLocated]);
	}
};

app.controller("triggerHistoryController", ['$scope', 'DataModel', '$timeout', 
                                            function($scope, DataModel, $timeout) {
	$scope.loading = true;
	
	// we don't start loading the graph straight away to improve the response speed
	$timeout(function() {
		DataModel.getCatalogueData(function(dataSet) {
			buildTriggerHistoryData(dataSet, $scope);
			
			$scope.chart = echarts.init(document.getElementById('chart-trigger-history'));
			window.addEventListener('resize', function() {
			    $scope.chart.resize();
			});
			setupTriggerHistoryBarChart($scope);
			
			// events coming from the data model
			var onDataChanged = function(e, events) {
				DataModel.getCatalogueData(function(dataSet) {
					buildTriggerHistoryData(dataSet, $scope);
					setupTriggerHistoryBarChart($scope);
				});
			};
			$scope.$on('eventDataChanged', onDataChanged);
			$scope.$on('triggerDataChanged', onDataChanged);
			
			$timeout(function() {
				$scope.loading = false;
			});
		});
	}, 10);
	}]);