var app = angular.module('msgWeb.controllers.3dView', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function setup3dViewPlot($scope) {
	var series = [
		// origin
		{
			name: 'Origin',
			type: 'scatter3D',
			symbolSize: 10,
			symbol: 'diamond',
			color: "red",
			data: $scope.data[0]
		},
		//stations
		{
			name: 'Stations',
			type: 'scatter3D',
			symbolSize: 5,
			symbol: 'rect',
			color: "blue",
			data: $scope.data[1],
			dimensions: [
				{ name: 'X', type: 'number', decimal: 2 },
				{ name: 'Y', type: 'number', decimal: 2 },
				{ name: 'Z', type: 'number', decimal: 2 },
				{ name: 'Name' }],
			label: {
				show: $scope.showLabels,
				formatter: function(param) {
					return param.data[3];
				},
			}
		},
		// events
		{
			name: 'Events',
			type: 'scatter3D',
			symbolSize: 5,
			data: $scope.data[2],
			dimensions: [
				{ name: 'X', type: 'number', decimal: 2 },
				{ name: 'Y', type: 'number', decimal: 2 },
				{ name: 'Z', type: 'number', decimal: 2 },
				{ name: 'Trigger ID', type: 'int' },
				{ name: 'Magnitude', type: 'number', decimal: 2 },
				{ name: 'Time', type: 'time' }],
			label: {
				show: $scope.showLabels,
				formatter: function(param) {
					return param.data[3];
				},
			}
		}
	];

	// boreholes
	for (var i = 0; i < $scope.boreholes.length; i++) {
		var data = $scope.data[i + 3];
		series.push({
			name: $scope.boreholes[i],
			type: 'line3D',
			color: "black",
			data: data,
			label: {
				show: $scope.showLabels,
				formatter: [$scope.boreholes[i]]
			}
		});
	}

	var min, max, formatter, dimension;
	if ($scope.colorMode === 'Magnitude') {
		dimension = 4;
		min = $scope.magnitudeMin;
		max = $scope.magnitudeMax;
	}
	else {
		dimension = 5;
		min = $scope.timeMin;
		max = $scope.timeMax;
		formatter = function(param) {
			return formatDate(new Date(param));
		};
	}

	$scope.chart.setOption({//dimensions
		tooltip: {
			formatter: function(params) {
				var tooltip = params.marker + params.seriesName;
				var dimensions = series[params.seriesIndex].dimensions;
				if (dimensions) {
					var bullet = "<br/><span style=\"display:inline-block;margin: 0px 4px 4px 15px;border-radius:5px;width:5px;height:5px;background-color:black;\"></span>";
					var value, dimension;
					for (let i = 0; i < dimensions.length; i++) {
						value = params.data[i];
						
						dimension = dimensions[i];
						switch(dimension.type) {
							case 'number':
								if(dimension.decimal == 0) {
									value = Math.round(value).toLocaleString();
								}
								else if(dimension.decimal) {
									value = value.toLocaleString(navigator.language, {maximumFractionDigits:dimension.decimal});
								}
								else {
									value = value.toLocaleString();
								}
								break;
								
							case 'int':
								value = value.toLocaleString();
								break;
								
							case 'time':
								value = formatDate(new Date(value));
								break;
						}
						
						tooltip += bullet + dimension.name + ": " + value;
					}
				}

				return tooltip;
			}
		},
		visualMap: [
			{
				show: $scope.hasEvents,
				top: 10,
				right: 10,
				calculable: false,
				orient: 'vertical',
				dimension: dimension,
				min: min,
				max: max,
				range: [min, max],
				formatter: formatter,
				seriesIndex: 2,
				text: [$scope.colorMode],
				inRange: {
					color: [
						'#1710c0',
						'#0b9df0',
						'#00fea8',
						'#00ff0d',
						'#f5f811',
						'#f09a09',
						'#fe0300'
					]
				}
			},
			{
				show: $scope.hasEvents,
				bottom: 10,
				left: 10,
				calculable: true,
				orient: 'vertical',
				dimension: 4,
				min: $scope.magnitudeMin,
				max: $scope.magnitudeMax,
				range: [$scope.magnitudeMin, $scope.magnitudeMax],
				seriesIndex: 2,
				inRange: { colorAlpha: 1 },
				outOfRange: { colorAlpha: 0 },
				text: ['Magnitude filter'],
			},
			{
				show: $scope.hasEvents,
				bottom: 10,
				right: 10,
				calculable: true,
				orient: 'vertical',
				dimension: 5,
				min: $scope.timeMin,
				max: $scope.timeMax,
				range: [$scope.timeMin, $scope.timeMax],
				seriesIndex: 2,
				inRange: { opacity: 1 },
				outOfRange: { opacity: 0 },
				text: ['Time filter'],
				formatter: function(param) {
					return formatDate(new Date(param));
				}
			}
		],
		legend: {
			show: true,
			orient: 'vertical',
			left: 10,
			top: 10,
			borderColor: '#333',
			borderWidth: 0.8,
		},
		grid3D: {
			viewControl: {
				projection: $scope.projectionMode.toLowerCase(),
				panMouseButton: 'right',
				minDistance: 5
			},
		},
		xAxis3D: {
			type: 'value',
			min: -$scope.xMax,
			max: $scope.xMax
		},
		yAxis3D: {
			type: 'value',
			min: -$scope.yMax,
			max: $scope.yMax
		},
		zAxis3D: {
			type: 'value',
			min: $scope.zMin,
			max: $scope.zMax
		},
		series: series
	});
};

function build3dViewData(boreholes, stations, events, $scope) {
	$scope.data = [[[0, 0, 0, null]], [], []];
	$scope.boreholes = [];
	var xMax = 0, yMax = 0, zMax = 0, zMin = 0;
	var magnitudeMax = 0, magnitudeMin = 0;
	var timeMax, timeMin;
	$scope.hasEvents = false;

	for (var i = 0; i < stations.length; i++) {
		var station = stations[i];
		$scope.data[1].push([station.x, station.y, station.z, station.stationName]);

		if (xMax < Math.abs(station.x)) {
			xMax = Math.abs(station.x);
		}
		if (yMax < Math.abs(station.y)) {
			yMax = Math.abs(station.y);
		}
		if (zMax < station.z) {
			zMax = station.z;
		}
		if (zMin > station.z) {
			zMin = station.z;
		}
	}

	for (var i = 0; i < events.length; i++) {
		var event = events[i];
		if (event.triggerType === "microseis") {
			var time = event.date.getTime();
			$scope.data[2].push([event.x, event.y, event.z, event.triggerID, event.magnitude, time]);

			if (xMax < Math.abs(event.x)) {
				xMax = Math.abs(event.x);
			}
			if (yMax < Math.abs(event.y)) {
				yMax = Math.abs(event.y);
			}
			if (zMax < event.z) {
				zMax = event.z;
			}
			if (zMin > event.z) {
				zMin = event.z;
			}
			if (magnitudeMax < event.magnitude) {
				magnitudeMax = event.magnitude;
			}
			if (magnitudeMin > event.magnitude) {
				magnitudeMin = event.magnitude;
			}
			if (!timeMax || timeMax < time) {
				timeMax = time;
			}
			if (!timeMin || timeMin > time) {
				timeMin = time;
			}
			$scope.hasEvents = true;
		}
	}

	for (var i = 0; i < boreholes.length; i++) {
		var coordinates = boreholes[i].coordinates;

		var array = [], coord;
		for (var j = 0; j < coordinates.length - 1; j++) {
			coord = coordinates[j];
			array.push([coord.x, coord.y, coord.z]);

			if (xMax < Math.abs(coord.x)) {
				xMax = Math.abs(coord.x);
			}
			if (yMax < Math.abs(coord.y)) {
				yMax = Math.abs(coord.y);
			}
			if (zMax < coord.z) {
				zMax = coord.z;
			}
			if (zMin > coord.z) {
				zMin = coord.z;
			}
		}
		coord = coordinates[coordinates.length - 1];
		array.push([coord.x, coord.y, coord.z]);
		$scope.data.push(array);
		$scope.boreholes.push(boreholes[i].name);
	}

	$scope.xMax = niceNum(xMax, false);
	$scope.yMax = niceNum(yMax, false);
	$scope.zMax = niceNum(zMax, false);
	$scope.zMin = -niceNum(-zMin, false);
	$scope.magnitudeMax = magnitudeMax;
	$scope.magnitudeMin = magnitudeMin;
	$scope.timeMin = timeMin ? timeMin : 0;
	$scope.timeMax = timeMax ? timeMax : new Date().getTime();
};

var colorMode3d, projectionMode3d, showLabels3d;

app.controller("3dViewController", ['$scope', 'DataModel', '$timeout',
	function($scope, DataModel, $timeout) {
		$scope.loading = true;
		$scope.showLabels = false;
		$scope.autoRotate = false;
		$scope.hasEvents = false;

		$scope.projectionMode = 'Perspective';
		$scope.projectionModes = ['Orthographic', 'Perspective'];

		$scope.colorMode = 'Magnitude';
		$scope.colorModes = ['Magnitude', 'Time'];

		if (showLabels3d) {
			$scope.showLabels = showLabels3d;
		}
		else {
			showLabels3d = $scope.showLabels;
		}

		if (colorMode3d) {
			$scope.colorMode = colorMode3d;
		}
		else {
			colorMode3d = $scope.colorMode;
		}

		if (projectionMode3d) {
			$scope.projectionMode = projectionMode3d;
		}
		else {
			projectionMode3d = $scope.projectionMode;
		}

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			DataModel.getBoreholes(function(boreholes) {
				DataModel.getStations(function(stations) {
					DataModel.getTriggers(function(triggers) { // triggers not required, but need to be loaded first for the trigger types
						DataModel.getEvents(function(events) {
							build3dViewData(boreholes, stations, events, $scope, DataModel);

							$scope.chart = echarts.init(document.getElementById('3d-view'));
							window.addEventListener('resize', function() {
								$scope.chart.resize();
							});

							setup3dViewPlot($scope);

							$timeout(function() {
								$scope.loading = false;
							});
						});
					});
				});
			});
		}, 10);

		$scope.updateView = function() {
			colorMode3d = $scope.colorMode;
			showLabels3d = $scope.showLabels;
			setup3dViewPlot($scope);
		};

		$scope.updateProjectionMode = function() {
			projectionMode3d = $scope.projectionMode;
			var projection = $scope.projectionMode.toLowerCase();
			$scope.chart.setOption({
				grid3D: {
					viewControl: {
						projection: projection
					}
				}
			});

			if (projection === "perspective") {
				$scope.chart.setOption({
					grid3D: {
						viewControl: {
							projection: projection
						}
					}
				});
			}
		};

		$scope.setView = function(alpha, beta) {
			$scope.chart.setOption({
				grid3D: {
					viewControl: {
						alpha: alpha,
						beta: beta
					}
				}
			});
		};

		$scope.autoRotateUpdate = function() {
			$scope.chart.setOption({
				grid3D: {
					viewControl: {
						autoRotate: $scope.autoRotate,
						autoRotateAfterStill: 0.1,
						autoRotateSpeed: 20
					}
				}
			});
		};

		// events coming from the data model
		$scope.$on('eventDataChanged', function(e, events) {
			$scope.updateView();
		});
	}]);
