var app = angular.module('msgWeb.controllers.triggerType', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

var setupTriggerTypePieChart = function($scope) {
	$scope.chart.setOption({
		legend: {
			orient: 'vertical',
			left: 'left'
		},
		series: [
			{
				name: 'Trigger types',
				type: 'pie',
				data: $scope.data
			}],
		animation: false
	});
}

function buildTriggerTypeData(dataSet, $scope) {
	var microseisCounter = 0;
	var earthquakeCounter = 0;
	var shotCounter = 0;
	var undefCounter = 0;
	var noiseCounter = 0;

	dataSet.sort(function(a, b) {
		return a.triggerID - b.triggerID;
	});

	var triggerType;
	for (var i = 0; i < dataSet.length; i++) {
		triggerType = dataSet[i].type;

		if (triggerType === "microseis") {
			microseisCounter++;
		}
		else if (triggerType === "earthquake") {
			earthquakeCounter++;
		}
		else if (triggerType === "shot") {
			shotCounter++;
		}
		else if (triggerType === "noise") {
			noiseCounter++;
		}
		else if (triggerType === "undef") {
			undefCounter++;
		}
	}

	$scope.data = [];
	if (microseisCounter != 0) {
		$scope.data.push({ value: microseisCounter, name: 'Microseis' });
	}
	if (earthquakeCounter != 0) {
		$scope.data.push({ value: earthquakeCounter, name: 'Earthquake' });
	}
	if (shotCounter != 0) {
		$scope.data.push({ value: shotCounter, name: 'Shot' });
	}
	if (undefCounter != 0) {
		$scope.data.push({ value: undefCounter, name: 'Undef' });
	}
	if (noiseCounter != 0) {
		$scope.data.push({ value: noiseCounter, name: 'Noise' });
	}
};

function onTriggerTypeDataChanged($scope, DataModel) {
	DataModel.getTriggers(function(dataSet) {
		buildTriggerTypeData(dataSet, $scope);
		$scope.plot.replot({ data: [$scope.data] });
	});
};

app.controller("triggerTypeController", ['$scope', 'DataModel', '$timeout',
	function($scope, DataModel, $timeout) {
		$scope.loading = true;

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			DataModel.getTriggers(function(dataSet) {
				buildTriggerTypeData(dataSet, $scope);

				$scope.chart = echarts.init(document.getElementById('chart-trigger-type'));
				window.addEventListener('resize', function() {
					$scope.chart.resize();
				});
				setupTriggerTypePieChart($scope);

				// events coming from the data model
				$scope.$on('triggerDataChanged', function() {
					onTriggerTypeDataChanged($scope, DataModel);
				});

				$timeout(function() {
					$scope.loading = false;
				});
			});
		}, 10);
	}]);