var app = angular.module('msgWeb.controllers.timeMagnitude', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function createTimeMagnitudeSeriesOptions($scope, Settings) {
	var seriesColors = getTimeMagnitudeSeriesColor($scope, Settings);
	var series = [];
	for (var i = 0; i < 4; i++) {
		series.push({
			id: i.toString(),
			type: 'scatter',
			symbolSize: i == 0 ? 10 : 5,
			symbol: i == 0 ? 'diamond' : 'circle',
			color: seriesColors[i],
			data: $scope.data[i]
		});
	}

	if ($scope.showTrafficLightRegions) {
		var criterion = Settings.getSetting('magnitudeCriterion');

		series.push({
			id: 'red',
			type: 'scatter',
			markArea: {
				itemStyle: {
					color: Settings.getSetting('redColor')
				},
				data: [
					[{ yAxis: criterion.amberRedValue }, { yAxis: Number.MAX_VALUE }],
				]
			}
		});
		series.push({
			id: 'amber',
			type: 'scatter',
			markArea: {
				itemStyle: {
					color: Settings.getSetting('amberColor')
				},
				data: [
					[{ yAxis: criterion.greenAmberValue }, { yAxis: criterion.amberRedValue }],
				]
			}
		});
		series.push({
			id: 'green',
			type: 'scatter',
			markArea: {
				itemStyle: {
					color: Settings.getSetting('greenColor')
				},
				data: [
					[{ yAxis: Number.MIN_VALUE }, { yAxis: criterion.greenAmberValue }],
				]
			}
		});
	}
	else {
		series.push({ id: 'red', type: 'scatter', markArea: { data: [] } });
		series.push({ id: 'amber', type: 'scatter', markArea: { data: [] } });
		series.push({ id: 'green', type: 'scatter', markArea: { data: [] } });
	}

	return series;
}

function setupTimeMagnitudePlot($scope, Settings) {
	// Draw the chart
	$scope.chart.setOption({
		dataZoom: [
			{
				type: 'slider',
				bottom: 5,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid: {
			left: 75,
			top: 30,
			right: 50,
			bottom: 80
		},
		xAxis: {
			name: "Event Time",
			type: 'time',
			nameLocation: 'middle',
			axisLabel: {
				formatter: {
					month: '{MMM}',
					day: '{d} {MMM}',
					hour: '{d}/{MM} {HH}:{mm}',
				}
			},
			axisLine: { onZero: false },
			nameGap: 30
		},
		yAxis: {
			name: config.magnitudeLabel,
			nameLocation: 'middle',
			axisLine: {
				show: true
			},
			min: -2,
			max: 2,
			nameGap: 50
		},
		series: createTimeMagnitudeSeriesOptions($scope, Settings),
		useUTC: true,
		animation: false
	});
};

function getTimeMagnitudeSeriesColor($scope, Settings) {
	if ($scope.showTrafficLightRegions) {
		return new Array(4).fill('blue');
	}

	var green = Settings.getSetting('greenColor');
	var red = Settings.getSetting('redColor');
	var amber = Settings.getSetting('amberColor');
	var criterion = Settings.getSetting('magnitudeCriterion');

	// getting the last item colour
	var lastItemColor = green;
	if ($scope.data[0][0]) {
		var value = $scope.data[0][0][1];
		if (value > criterion.greenAmberValue) {
			lastItemColor = amber;
			if (value > criterion.amberRedValue) {
				lastItemColor = red;
			}
		}
	}

	return [lastItemColor, green, amber, red];
};

function buildTimeMagnitudeData(events, $scope, Settings) {
	var criterion = Settings.getSetting('magnitudeCriterion');
	$scope.data = [[], [], [], []];
	$scope.max = null;

	var count = 0;
	if (events.length > 0) {
		var index, value;
		for (var i = 0; i < events.length - 1; i++) {
			value = events[i];
			if (!value.magnitude) {
				continue;
			}
			index = 1;
			if (criterion && value.magnitude > criterion.greenAmberValue) {
				index++;
				if (value.magnitude > criterion.amberRedValue) {
					index++;
				}
			}
			$scope.data[index].push([value.date.getTime(), value.magnitude]);
			count++;
		}

		// last value
		if (count > 0) {
			var lastValue = events[events.length - 1];
			var magnitude = lastValue.magnitude ? lastValue.magnitude : NaN;
			$scope.data[0].push([lastValue.date.getTime(), magnitude]);
			$scope.max = lastValue.date.getTime();
		}
	}

	if (count == 0) {
		$scope.data[0].push([0, 0]);
	}
};

app.controller("timeMagnitudeController", ['$scope', 'DataModel', '$timeout', 'Settings',
	function($scope, DataModel, $timeout, Settings) {
		$scope.showTrafficLightRegions = false;
		$scope.loading = true;

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			Settings.onSettingsLoaded(function() { // settings need to be loaded first
				$scope.canShowTrafficLights = Settings.hasSetting('magnitudeCriterion');

				DataModel.getEvents(function(events) {
					buildTimeMagnitudeData(events, $scope, Settings);
					$scope.chart = echarts.init(document.getElementById('chart-time-magnitude'));
					window.addEventListener('resize', function() {
						$scope.chart.resize();
					});
					setupTimeMagnitudePlot($scope, Settings);

					// events coming from the data model
					$scope.$on('eventDataChanged', function(e, events) {
						setupTimeMagnitudePlot($scope, Settings);
					});

					$timeout(function() {
						$scope.loading = false;
					});
				});
			});
		}, 10);

		$scope.onShowTrafficLightRegionsChanged = function() {
			setupTimeMagnitudePlot($scope, Settings);
		};

		$scope.showLast30Days = function() {
			var option = $scope.chart.getOption();
			var endValue = option.dataZoom[0].endValue;
			var startValue = option.dataZoom[0].startValue;
			var newStartValue = endValue - 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

			if (startValue < newStartValue) {
				$scope.chart.setOption({
					dataZoom: [
						{
							type: 'slider',
							startValue: newStartValue
						}
					]
				});
			}
		};
	}]);
