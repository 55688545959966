angular.module('mapService', [])
	
	.factory('MapBuilder', ['$timeout',
	                       function($timeout){
		
	//icon creation
	var originIcon = L.icon({
		iconUrl: './images/origin.png',
		iconSize: [24, 24],
		iconAnchor: [12, 12],
		popupAnchor: [0, 0],
	});
	var stationIcon = L.icon({
		iconUrl: './images/station.png',
		iconSize: [24, 24],
		iconAnchor: [12, 12],
		popupAnchor: [0, 0],
	});
	
	return {
		getOriginIcon: function() {
			return originIcon;
		},
		
		getStationIcon: function() {
			return stationIcon;
		},
		
		//Convert a JSON of users into map points
		convertToMapPoints: function(dataset) {

			var locations = [];
			for(var i = 0; i < dataset.length; i++) {
				var data = dataset[i];
				
				locations.push({
					latitude: data.location[1],
					longitude: data.location[0],
					name: data.toString()
				});
			}
			return locations;
		},
		
		createEmptyMap: function(overlays) {
			var mapTileLayer = L.tileLayer(
					'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
					{
						id: 'openStreet', 
						maxZoom: 19,
						attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
					});
					
			var blankTileLayer = L.tileLayer('',  { id: 'blank'});

			// Create a new map and place in the index.html page
			var map = L.map('map', {
				zoom: 1,
				maxZoom: 19,
				center: [0., 0.],
				layers: mapTileLayer
			});
			var baseMaps = {
					"Map": mapTileLayer,
					"Blank": blankTileLayer
			};
			L.control.layers(baseMaps, overlays).addTo(map);
			
			return map;
		},
		
		addOriginMarker: function(map, location) {
			return this.addMarker(map, location, 'Origin', this.getOriginIcon(), '<text><b>Origin</b></text>');
		},
		
		addStationMarker: function(map, location, name) {
			return this.addMarker(map, location, name, this.getStationIcon(), '<text><b>Station name</b>: ' + name + '</text>');
		},
		
		addMarker: function(map, location, title, icon, tooltip) {
			var marker = L.marker([location.latitude, location.longitude], {
				title: title,
				icon: icon
			});
			map.addLayer(marker);
			marker.bindPopup(tooltip);
			return marker;
		},
		
		// sets the centre and zoom on a series of locations
		fitLocations: function(map, locations) {
			if(locations.length > 0) {
				var bounds = [];
				for(var i = 0; i < locations.length; i++) {
					bounds[i] = [locations[i].latitude, locations[i].longitude];
				}
				map.fitBounds(bounds);
			}
		},
		
		createControl: function(map, pos, innerHTML, type) {
			var control = L.control({position: pos});
			control.onAdd = function (map) {
				var div = L.DomUtil.create('div', type);
				div.innerHTML = innerHTML;
				return div;
			};
			return control;
		},
		
		createStationAndOriginLegend: function(map) {
			this.createControl(map, 'bottomleft',
					'<table>'
					+ '<tr><td><img src="./images/origin.png" height="16" width="16"></td><td style="font-size:12px">&nbsp;Origin</td></tr>'
					+ '<tr><td><img src="./images/station.png" height="16" width="16"></td><td style="font-size:12px">&nbsp;Station</td></tr>'
					+ '</table>',
			'info legend').addTo(map);
		},
		
		addStationsAndOrigin: function(map, originLocation, stationLocations) {
			// displays the origin on the map
			originLocation.marker = this.addOriginMarker(map, originLocation);
			
			// Loop through each location in the array and place a marker
			var thisObj = this;
			stationLocations.forEach(function(n, i){
				n.marker = thisObj.addStationMarker(map, n, n.name);
			});
			
			// station and origin legend
			this.createStationAndOriginLegend(map);
		},
		

		buildLegendTableBody: function(items) {
			var res = '';
			items.forEach(function(item) {
				res += '<tr><td><div style="height:15px">' + item.representation + '</div></td>'
						+ '<td><div style="height:15px; font-size:12px">&nbsp;' + item.value + '</div></td></tr>';
			});
			return res;
		}
	};
}]);
