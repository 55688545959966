var app = angular.module('msgWeb.controllers.triggerTypeHistory', [
  'ngRoute',
  'mobile-angular-ui',
  'mobile-angular-ui.core'
]);

var setupTriggerTypeHistoryBarChart = function($scope) {
	// Draw the chart
	$scope.chart.setOption({
		legend: {
			show: true,
			orient: 'vertical',
			left: 100,
			top: 30,
			backgroundColor: 'white',
			z: 10, // on top of everything
			borderColor: '#333',
        	borderWidth: 0.8,
        	data: [
				{
					name: 'Microseis',
					icon: 'rect',
				},
				{
					name: 'Earthquake',
					icon: 'rect',
				},
				{
					name: 'Shot',
					icon: 'rect',
				},
				{
					name: 'Noise',
					icon: 'rect',
				},
				{
					name: 'Located events'
				}],
			selected: { 
				'Located events': false,
				'Noise': false
			}
		},
		dataZoom: [
			{
				type: 'slider',
				bottom: 40,
				dataBackground:{
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground:{
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid:{
			left: 75,
			top: 10,
			right: 50,
			bottom: 120
		},
		xAxis: {
			name: "Trigger Time",
			type: 'time',
			nameLocation: 'middle',
			axisLabel: {
				formatter: {
					month: '{MMM}',
					day: '{d} {MMM}',
    				hour: '{d}/{MM} {HH}:{mm}',
				}
			},
			nameGap: 30
		},
		yAxis: {
			name: "Cumulative Count",
			nameLocation: 'middle',
			axisLine: {
				show: true
			},
			min: 0,
			nameGap: 50
		},
		series: [
			{
				name: 'Microseis',
				type: 'line',
      			stack: 'Total',
      			areaStyle: {},
				emphasis: {
					focus: 'series'
				},
				data: $scope.microseisData,
				showSymbol: false
		    },
			{
				name: 'Earthquake',
				type: 'line',
     			stack: 'Total',
      			areaStyle: {},
				emphasis: {
					focus: 'series'
				},
				data: $scope.earthquakeData,
				showSymbol: false
		    },
			{
				name: 'Shot',
				type: 'line',
      			stack: 'Total',
      			areaStyle: {},
				emphasis: {
					focus: 'series'
				},
				data: $scope.shotData,
				showSymbol: false
		    },
			{
				name: 'Noise',
				type: 'line',
      			stack: 'Total',
      			areaStyle: {},
				emphasis: {
					focus: 'series'
				},
				data: $scope.noiseData,
				showSymbol: false
		    },
			{
				name: 'Located events',
				type: 'line',
				data: $scope.locatedEvents,
				showSymbol: false, 
				animation: false,
				symbol: 'none',
				symbolSize: 0,
				lineStyle: {
					type: 'solid',
					color: '#1e90ff'
				},
				itemStyle: {
					opacity: 0
				},
				markLine : {
					lineStyle: {
					  type: 'solid',
					  color: '#1e90ff'
					},
					symbol: 'none',
				    data : $scope.locatedEventsData,
				    label: { show: false }
				}
		    }
		],
		useUTC: true,
		animation: false
	});
}

function buildTriggerTypeHistoryData(dataSet, $scope) {
	$scope.microseisData = [];
	$scope.earthquakeData = [];
	$scope.shotData = [];
	$scope.noiseData = [];
	$scope.locatedEventsData = [];
	$scope.max = 0;
	
	var microseisCounter = 0, earthquakeCounter = 0, shotCounter = 0, noiseCounter = 0;
	
	dataSet.sort(function(a, b) {
		return a.date.getTime() - b.date.getTime();
	});
	
	var time, triggerType, d;
	for (var i = 0; i < dataSet.length; i++) {
		d = dataSet[i];
		triggerType = d.triggerType;
		time = d.date.getTime();
		if(time > $scope.max) {
			$scope.max = time;
		}
		if(d.x) {
			$scope.locatedEventsData.push({ xAxis: time });
		}
		
		if(triggerType === "microseis") {
			microseisCounter++;
		}
		else if(triggerType === "earthquake") {
			earthquakeCounter++;
		}
		else if(triggerType === "shot") {
			shotCounter++;
		}
		else if(triggerType === "noise") {
			noiseCounter++;
		}
		$scope.microseisData.push([time, microseisCounter]);
		$scope.earthquakeData.push([time, earthquakeCounter]);
		$scope.shotData.push([time, shotCounter]);
		$scope.noiseData.push([time, noiseCounter]);
	}
};

app.controller("triggerTypeHistoryController", ['$scope', 'DataModel', '$timeout', 
                                            function($scope, DataModel, $timeout) {
	$scope.loading = true;
	
	// we don't start loading the graph straight away to improve the response speed
	$timeout(function() {
		DataModel.getCatalogueData(function(dataSet) {
			buildTriggerTypeHistoryData(dataSet, $scope);
			
			$scope.chart = echarts.init(document.getElementById('chart-trigger-type-history'));
			window.addEventListener('resize', function() {
			    $scope.chart.resize();
			});
			setupTriggerTypeHistoryBarChart($scope);
			
			// events coming from the data model
			var onDataChanged = function(e, events) {
				DataModel.getCatalogueData(function(dataSet) {
					buildTriggerTypeHistoryData(dataSet, $scope);
					setupTriggerTypeHistoryBarChart($scope);
				});
			};
			$scope.$on('triggerDataChanged', onDataChanged);
			
			$timeout(function() {
				$scope.loading = false;
			});
		});
	}, 10);
}]);