var app = angular.module('msgWeb.controllers.Settings', [
  'ngRoute',
  'mobile-angular-ui',
  'mobile-angular-ui.core'
]);

app.controller('settingsController', ['$scope', '$timeout', 'Settings', '$rootScope',
                                       function($scope, $timeout, Settings, $rootScope) {
	$scope.loading = true;
	
	//analyst
	$scope.currentAnalyst = currentAnalyst;
	$scope.analysts = analysts;
	$scope.onAnalystChangedChanged = function() {
		currentAnalyst = $scope.currentAnalyst;
		$rootScope.$broadcast('analystChanged', currentAnalyst.name);
	};
	
	// criteria
	$scope.criteria = [];
	$scope.criteria.push({name: config.magnitudeLabel });
	$scope.criteria.push({name: 'Depth'});
	if(config.displaySeismicMoment || config.displayCumulativeSeismicMoment) {
		$scope.criteria.push({name: 'Seismic moment release rate'});
	}
	if(config.displaySeismicMoment) {
		$scope.criteria.push({name: 'Seismic moment'});
	}
	$scope.criteria.push({name: 'Peak velocity'});
	
	// Peak velocity stations
	$scope.peakVelocityStations = [];
	
	for (var i = 0; i < $scope.criteria.length; i++) {
		$scope.criteria[i].amberCriterion = 'Undefined';
		$scope.criteria[i].redCriterion = 'Undefined';
	}
	
	Settings.onSettingsLoaded(function() {
		$timeout(function() {
			$scope.peakVelocityStations = Settings.getSetting('velMaxStations');
			var vMaxStartTime = Settings.getSetting('velMaxStartTime');
			
			if(vMaxStartTime) {
				$scope.vMaxStartTimeStr = formatDate(vMaxStartTime);
			}
			
			var index = 0;
			var criterion = Settings.getSetting('magnitudeCriterion');
			if(criterion) {
				$scope.criteria[index].amberCriterion = config.magnitudeSymbol + ' ≥ ' + criterion.greenAmberValue;
				$scope.criteria[index].redCriterion = config.magnitudeSymbol + ' ≥ ' + criterion.amberRedValue;
			}
			
			index++;
			var minDepth = Settings.getSetting('minDepthCriterion');
			var maxDepth = Settings.getSetting('maxDepthCriterion');
			if(maxDepth) {
				$scope.criteria[index].amberCriterion = 'Z ≤ ' + maxDepth.greenAmberValue + 'm';
				$scope.criteria[index].redCriterion = 'Z ≤ ' + maxDepth.amberRedValue + 'm';
				
				if(minDepth) {
					$scope.criteria[index].amberCriterion += ' or ';
					$scope.criteria[index].redCriterion += ' or ';
				}
			}
			
			if(minDepth) {
				if(!maxDepth) {
					$scope.criteria[index].amberCriterion = '';
					$scope.criteria[index].redCriterion = '';
				}
				$scope.criteria[index].amberCriterion += minDepth.greenAmberValue + 'm ≤ Z';
				$scope.criteria[index].redCriterion += minDepth.amberRedValue + 'm ≤ Z';
			}
			
			if(config.displaySeismicMoment || config.displayCumulativeSeismicMoment) {
				index++;
				criterion = Settings.getSetting('seismicMomentReleaseRateCriterion');
				if(criterion) {
					$scope.criteria[index].amberCriterion = 'δMo ≥ ' + criterion.greenAmberValue;
					$scope.criteria[index].redCriterion = 'δMo ≥ ' + criterion.amberRedValue;
				}
			}
			
			if(config.displaySeismicMoment) {
				index++;
				criterion = Settings.getSetting('seismicMomentSumCriterion');
				if(criterion) {
					$scope.criteria[index].amberCriterion = '∑Mo per hour ≥ ' + criterion.greenAmberValue + 'J';
					$scope.criteria[index].redCriterion = '∑Mo per hour ≥ ' + criterion.amberRedValue + 'J';
				}
			}
			
			index++;
			criterion = Settings.getSetting('peakVelocityCriterion');
			if(criterion) {
				$scope.criteria[index].amberCriterion = 'Peak velocity ≥ ' + criterion.greenAmberValue + ' mm/s';
				$scope.criteria[index].redCriterion = 'Peak velocity ≥ ' + criterion.amberRedValue + ' mm/s';
			}
			
			$scope.loading = false;
		});
	});
}]);
