var app = angular.module('msgWeb.controllers.Map', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core',
	'mobile-angular-ui.gestures.touch'
]);

var widthArray = [10, 14, 18, 22, 26];
var defaultWidth = 10;

app.controller("mapController", ['$scope', 'MapBuilder', 'DataModel', 'SharedState', '$timeout',
	function($scope, MapBuilder, DataModel, SharedState, $timeout) {

		$scope.scaleByMagnitude = false;
		$scope.filter = 'none';
		$scope.hasMagnitudes = false;
		$scope.mouseOnTimeFilter = false;

		// get the data
		DataModel.getProject(function(project) {
			$scope.projectLocation = {
				latitude: project.refLocation[1],
				longitude: project.refLocation[0]
			}
		});

		DataModel.getStations(function(stations) {
			$scope.stationLocations = MapBuilder.convertToMapPoints(stations);
		});

		DataModel.getAllEvents(function(events) {
			$scope.eventLocations = MapBuilder.convertToMapPoints(events);
			events.forEach(function(event, i) {
				$scope.eventLocations[i].historical = event.historical;
				$scope.eventLocations[i].triggerType = event.triggerType;
				$scope.eventLocations[i].time = event.date.getTime();
			});

			// checking if there is at least two magnitudes
			var nMagnitude = 0;
			for (var i = 0; i < events.length; i++) {
				if (events[i].magnitude) {
					if (nMagnitude == 1) {
						$scope.hasMagnitudes = true;
						break;
					}
					nMagnitude++;
				}
			}

			// getting the max time values
			if (events.length == 0) {
				$scope.minTime = new Date();
				$scope.maxTime = new Date();
			}
			else {
				$scope.minTime = events[0].date;
				$scope.maxTime = events[0].date;

				for (var i = 0; i < events.length; i++) {
					var date = events[i].date;

					// getting the max time values
					if ($scope.minTime > date) {
						$scope.minTime = date;
					}
					if ($scope.maxTime < date) {
						$scope.maxTime = date;
					}
				}
			}

			$scope.currentMinTime = $scope.minTime;
			$scope.currentMaxTime = $scope.maxTime;

			// getting the max amplitude values
			if ($scope.hasMagnitudes) {
				var minMagnitude = Number.MAX_VALUE, maxMagnitude = -Number.MAX_VALUE;
				events.forEach(function(d) {
					if (d.magnitude) {
						if (minMagnitude > d.magnitude) {
							minMagnitude = d.magnitude;
						}
						if (maxMagnitude < d.magnitude) {
							maxMagnitude = d.magnitude;
						}
					}
				});

				// calculating the icon sizes
				var intervalLength = (maxMagnitude - minMagnitude) / 5., interval;
				var width;
				for (var i = 0; i < events.length; i++) {
					interval = Math.floor((events[i].magnitude - minMagnitude) / intervalLength);
					width = null;
					switch (interval) {
						case 0:
							width = widthArray[0];
							break;
						case 1:
							width = widthArray[1];
							break;
						case 2:
							width = widthArray[2];
							break;
						case 3:
							width = widthArray[3];
							break;
						case 4:
						case 5: // when value == max value
							width = widthArray[4];
							break;
					}
					$scope.eventLocations[i].magnitudeScaleWidth = width;
				}
			}

			var doInitMap = function() {
				initializeMap($scope, $timeout, MapBuilder);
			};
			$scope.initMap = function() {
				if ($scope.stationLocations && $scope.projectLocation) {
					doInitMap();
				}
				else {
					$timeout(function() {
						$scope.initMap();
					});
				}
			};
			$scope.initMap();
		});

		// event sent from the side bar to select (set it as centre + show popup) an event or a station
		$scope.$on('selectObject', function(event, args) {
			var locations;
			switch (args.type) {
				case 'station':
					locations = $scope.stationLocations;
					break;

				case 'event':
					locations = $scope.eventLocations;
					break;
			}
			// we close the current popup
			$scope.map.closePopup();

			if (locations) {
				var location;
				for (var i = 0; i < locations.length; i++) {
					location = locations[i];
					if (location.name === args.name) {
						SharedState.turnOff('uiSidebarLeft'); // closes the side bar
						var latlng = new L.LatLng(location.latitude, location.longitude);

						switch (args.type) {
							case 'station':
								$scope.map.panTo(latlng);
								location.marker.openPopup();
								break;

							case 'event':
								// time filter check
								var minTime = $scope.currentMinTime.getTime();
								var maxTime = $scope.currentMaxTime.getTime();
								if (minTime <= location.time && location.time <= maxTime) {
									var latlng = new L.LatLng(location.latitude, location.longitude);
									$scope.map.panTo(latlng);
									var popup = L.popup()
										.setLatLng(latlng)
										.setContent('<text><b>Event</b>: ' + location.name + '</text>')
										.openOn($scope.map);
								}
								break;
						}
						break;
					}
				}
			}
		});
	}]);

//Private Inner Functions
//--------------------------------------------------------------

/**
 * Disables some mouse actions on control areas
 */
var disableMouseOn = function(control, map) {
	// Disable dragging when user's cursor enters the element
	control.getContainer().addEventListener('mouseover', function(event) {
		if (event.buttons == 0) { // no pressed button
			map.dragging.disable();
			map.doubleClickZoom.disable();
		}
	});
	// Re-enable dragging when user's cursor leaves the element
	control.getContainer().addEventListener('mouseout', function() {
		map.dragging.enable();
		map.doubleClickZoom.enable();
	});
};

/**
 * Initialises the map
 */
function initializeMap($scope, $timeout, MapBuilder) {
	$scope.map = MapBuilder.createEmptyMap();
	var map = $scope.map;

	MapBuilder.addStationsAndOrigin(map, $scope.projectLocation, $scope.stationLocations);

	// we display the events in a canvas to improve the speed (especially for Edge and IE)
	var canvas = L.gridLayer();
	$scope.canvas = canvas;
	canvas.createTile = function(coords) {
		var map = $scope.map;
		// create a <canvas> element for drawing
		var canvas = L.DomUtil.create('canvas', 'event-leaflet-tile');

		// setup tile width and height according to the options
		var tileSize = this.getTileSize();
		canvas.width = tileSize.x;
		canvas.height = tileSize.y;

		// get a canvas context and draw something on it using coords.x, coords.y and coords.z
		var context = canvas.getContext('2d');

		// start point
		var start = tileSize.scaleBy(L.point(coords.x, coords.y));

		// event width e.g circle radius
		var width = defaultWidth;

		// common settings
		context.lineWidth = 1;

		// unknown moment icon
		var unknownImage = new Image();
		unknownImage.src = './images/unknown.png';
		var imageHalfSize = 6;

		// min and max time
		var minTime = $scope.currentMinTime.getTime();
		var maxTime = $scope.currentMaxTime.getTime();

		// array which contains the geometric data of each displayed event 
		// used for the mouse interactions
		var eventShapes = [];

		for (var i = 0; i < $scope.eventLocations.length; i++) {
			var location = $scope.eventLocations[i];

			// time filter
			if (minTime > location.time || location.time > maxTime) {
				continue;
			}

			// actual coordinates to tile pixel
			var p = map.project(new L.LatLng(location.latitude, location.longitude), coords.z);

			// point to draw
			var x = Math.round(p.x - start.x);
			var y = Math.round(p.y - start.y);

			// we check if we need to scale the size of the event shapes
			if ($scope.magnitudeCheckbox) {
				if (typeof location.magnitudeScaleWidth === 'number') {
					width = location.magnitudeScaleWidth;
				}
				else {
					// we display the question mark icon instead
					context.drawImage(unknownImage, x - imageHalfSize, y - imageHalfSize);

					eventShapes.push({
						x: x,
						y: y,
						width: imageHalfSize,
						location: location
					});
					continue;
				}
			}

			if (x > -width && y > -width && x < (tileSize.x + width) && y < (tileSize.y + width)) {
				drawShape(context, x, y, width, location);
				eventShapes.push({
					x: x,
					y: y,
					width: width,
					location: location
				});
			}
		}

		// function to detect the event where the mouse is. It gets the first event detected.
		var getEventAt = function(x, y, tolerance) {
			if (typeof (tolerance) === 'undefined') tolerance = 0;

			var eventShape, closestShape, closestDistance = Number.MAX_VALUE;
			var abs = Math.abs; // for optimisation
			var distance, distX, distY, threshold;

			for (var i = 0; i < eventShapes.length; i++) {
				eventShape = eventShapes[i];
				distX = abs(x - eventShape.x);
				distY = abs(y - eventShape.y);
				distance = distX * distX + distY * distY;

				threshold = eventShape.width + tolerance;
				threshold *= threshold; // as we are using the square distance

				if (distance < threshold && distance < closestDistance) {
					closestDistance = distance;
					closestShape = eventShape;
				}
			}
			return closestShape;
		}

		// when clicking on an event, a pop-up appears
		var showEventAt = function(x, y, tolerance) {
			var event = getEventAt(x, y, tolerance);
			if (event) {
				$timeout(function() {
					var location = event.location;
					L.popup()
						.setLatLng(new L.LatLng(location.latitude, location.longitude))
						.setContent('<text><b>Event</b>: ' + location.name + '</text>')
						.openOn(map);
				});
			}
		};

		if (isTouchScreen) {
			canvas.addEventListener('click', function(e) {
				showEventAt(e.offsetX, e.offsetY, 50);
			});
		}
		else {
			// we change the cursor when hovering above an event
			var currentEvent;
			canvas.addEventListener('mousemove', function(e) {
				var event = getEventAt(e.offsetX, e.offsetY);
				if (currentEvent != event) {
					currentEvent = event;

					document.getElementById('map').style.cursor = event ? 'pointer' : '';

					// tooltip
					// getting the element from where the event comes from
					var element = e.target;
					if (!element) {
						element = e.srcElement;
					}

					if (element) {
						$timeout(function() {
							if (event) {
								// displaying the tooltip where the cursor is
								element.title = 'Event: ' + event.location.name;
							}
							else {
								element.title = '';
							}
						});
					}
				}
			});

			// we get the position when starting the click
			var downPosition = { x: 0, y: 0 };
			canvas.addEventListener('mousedown', function(e) {
				downPosition = { x: e.screenX, y: e.screenX };
			});

			canvas.addEventListener('click', function(e) {
				// we check if the user has been dragging (instead of clicking), in this case, we ignore the event
				if (Math.abs(e.screenX - downPosition.x) < 2 && Math.abs(e.screenX - downPosition.y) < 2) {
					showEventAt(e.offsetX, e.offsetY);
				}
			});
		}
		// return the tile so it can be rendered on screen
		return canvas;
	};
	map.addLayer(canvas);

	// canvas needs to be all the time at the front
	canvas.bringToFront();
	map.on('baselayerchange', function(e) {
		canvas.bringToFront();
	});

	// we set the centre and zoom
	MapBuilder.fitLocations(map, $scope.stationLocations.concat($scope.eventLocations));

	// event legend
	MapBuilder.createControl(map, 'bottomleft', buildHtmlEventLegend(MapBuilder), 'info legend').addTo(map);

	var innerHTML = '<div style="margin-right: 25px; margin-left: 25px;" id="slider"/>';
	var control = MapBuilder.createControl(map, 'bottomright', innerHTML, 'info');
	control.addTo(map);

	var slider = $("#slider");
	slider.dateRangeSlider({
		formatter: function(value) {
			var month = value.getUTCMonth() + 1, day = value.getUTCDate();
			return "" + value.getUTCFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
		},
		bounds: {
			min: $scope.minTime.valueOf(),
			max: $scope.maxTime.valueOf()
		},
		defaultValues: {
			min: $scope.minTime.valueOf(),
			max: $scope.maxTime.valueOf()
		},
		arrows: false
	});
	
	var resizeTimeFilterControl = function() {
		var mapWidth = document.getElementById("map").offsetWidth;
		var width = Math.floor(mapWidth * 0.75);
		var height = 60;

		var slider = document.getElementById("slider");
		slider.parentElement.style.width = width + "px";
		slider.parentElement.style.height = height + "px";
		
		$("#slider").resize();
	}

	window.addEventListener('resize', resizeTimeFilterControl);
	resizeTimeFilterControl();

	slider.on("valuesChanged", function(e, data) {
		var min = data.values.min;
		var max = data.values.max;

		$scope.currentMinTime = new Date(Date.UTC(min.getUTCFullYear(), min.getUTCMonth(), min.getUTCDate()));
		$scope.currentMaxTime = new Date(Date.UTC(max.getUTCFullYear(), max.getUTCMonth(), max.getUTCDate() + 1)); // the day after at midnight
		$scope.canvas.redraw();
	});
	
	// Disable dragging when user's cursor enters the element
	control.getContainer().addEventListener('mouseover', function(event) {
		if (event.buttons == 0) { // no pressed button
			map.dragging.disable();
			map.doubleClickZoom.disable();
		}
	});
	// Re-enable dragging when user's cursor leaves the element
	$("#slider").parent().mouseleave(function() {
		map.dragging.enable();
		map.doubleClickZoom.enable();
	});

	// Scale by magnitude
	if ($scope.hasMagnitudes) {
		var innerHTML = '<form><input id="magnitudeCheckbox" type="checkbox"/>'
			+ '<text style="font-size:12px"> Scale by ' + config.magnitudeSymbol + '</text></form>';
		var control = MapBuilder.createControl(map, 'topright', innerHTML, 'info');
		control.addTo(map);
		disableMouseOn(control, map);

		document.getElementById("magnitudeCheckbox").addEventListener("click", function() {
			$scope.magnitudeCheckbox = document.getElementById("magnitudeCheckbox").checked;
			canvas.redraw();
		}, false);
	}

	$scope.$on('$destroy', function() {
		map.remove();
	});
};

function createRectangle(height, width) {
	return [{ x: -width / 2, y: -height / 2 }, { x: width / 2, y: -height / 2 }, { x: width / 2, y: height / 2 }, { x: -width / 2, y: height / 2 }];
}

function createDiamond(height, width) {
	return [{ x: 0, y: -height / 2 }, { x: width / 2, y: 0 }, { x: 0, y: height / 2 }, { x: -width / 2, y: 0 }];
}

function createCirclePolyline(radius, startAngle, pointCount) {
	var angleOffset = 2 * Math.PI / pointCount;
	var i, angle = startAngle, polyline = [];
	for (i = 0; i < pointCount; i++) {
		polyline.push({
			x: Math.cos(angle) * radius,
			y: Math.sin(angle) * radius
		});
		angle += angleOffset;
	}
	return polyline;
}

function createStar(width) {
	var smallCircle = createCirclePolyline(width / 6, -Math.PI / 2 + Math.PI / 5, 5);
	var bigCircle = createCirclePolyline(width / 2, -Math.PI / 2, 5);
	var polyline = [];
	for (i = 0; i < 5; i++) {
		polyline.push(bigCircle[i]);
		polyline.push(smallCircle[i]);
	}
	return polyline;
}

function addOffset(xOffset, yOffset, shape) {
	shape.forEach(function(d) {
		d.x += xOffset;
		d.y += yOffset;
	});
	return shape;
}

function roundShape(shape) {
	shape.forEach(function(d) {
		d.x = Math.round(d.x);
		d.y = Math.round(d.y);
	});
	return shape;
}

function toString(shape) {
	var res = '';
	shape.forEach(function(d, i) {
		if (i > 0) { res += ' '; }
		res += d.x + ',' + d.y;
	});
	return res;
}

function drawShape(context, x, y, width, location) {
	if (location.historical) {
		context.strokeStyle = "rgba(0, 0, 0, 0.5)";
		context.fillStyle = "rgba(0, 0, 0, 0.2)";
	}
	else {
		context.strokeStyle = "rgba(49, 90, 35, 0.5)";
		context.fillStyle = "rgba(49, 90, 35, 0.2)";
	}

	context.beginPath();
	if (location.triggerType === "microseis") {
		context.arc(x, y, width / 2, 0, 2 * Math.PI, true);
	}
	else {
		var shape = createShape(location.triggerType, width);
		if (shape != null) {
			shape = addOffset(x, y, shape);
			context.moveTo(shape[0].x, shape[0].y);
			for (var i = 1; i < shape.length; i++) {
				context.lineTo(shape[i].x, shape[i].y);
			}
			context.closePath();
		}
	}
	context.fill()
	context.stroke();
}

function createShape(triggerType, width) {
	switch (triggerType) {
		case "earthquake":
			return createDiamond(width, width * 2 / 3);

		case "shot":
			return createStar(width);
	}
	return null;
}

function buildHtmlEventLegend(MapBuilder) {
	var eventParameters = 'stroke="green" stroke-opacity="1" stroke-width="1" fill="green" fill-opacity="0.4"';
	var historicalEventParameters = 'stroke="black" stroke-opacity="0.5" stroke-width="1" fill="black" fill-opacity="0.2"';

	var xOffset = 6, yOffset = 8;
	var microseisEvent = 'circle cx="' + xOffset + '" cy="' + yOffset + '" r="4"'; 													// circle
	var earthquakeEvent = 'polygon points = "' + toString(roundShape(addOffset(xOffset, yOffset, createDiamond(10, 8)))) + '"'; 	// diamond
	var shotEvent = 'polygon points = "' + toString(roundShape(addOffset(xOffset, yOffset, createStar(12)))) + '"'; 				// star
	var categoryEvent = 'polygon points = "' + toString(roundShape(addOffset(xOffset, yOffset, createRectangle(8, 10)))) + '"'; 	// rectangle

	var categoryItems = [
		{ eventCategory: eventParameters, value: 'Event' },
		{ eventCategory: historicalEventParameters, value: 'Historical event' }
	];
	categoryItems.forEach(function(item) {
		item.eventType = categoryEvent;
	});

	var typeItems = [
		{ eventType: microseisEvent, value: 'Microseis event' },
		{ eventType: shotEvent, value: 'Shot' },
		{ eventType: earthquakeEvent, value: 'Earthquake' }
	];
	typeItems.forEach(function(item) {
		item.eventCategory = historicalEventParameters;
	});

	return buildTable(MapBuilder, 'Event category<br>', categoryItems)
		+ buildTable(MapBuilder,
			'<p style="margin-top:3px;margin-bottom:0;">Event type</p>',
			typeItems);
};

function buildTable(MapBuilder, title, items) {
	var svgStyle = 'style="width: 15px; height: 15px;"';
	items.forEach(function(item) {
		item.representation = '<svg ' + svgStyle + '><' + item.eventType + item.eventCategory + '/></svg>';
	});
	return title + '<table>' + MapBuilder.buildLegendTableBody(items) + '</table>';
};
